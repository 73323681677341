<div class="form-div form-icon" [ngStyle]="{ width: getWidth() }">
  <img [src]="icon" *ngIf="icon" />
  <input
    [type]="type"
    class="form-input"
    id="inputEmail"
    [required]="isRequired"
    [ngModel]="value"
    #inputEmail="ngModel"
    (focusout)="validate(inputEmail)"
    (input)="validate(inputEmail)"
    [placeholder]="placeholder"
    (ngModelChange)="change($event)"
    [disabled]="disabled"
  />
  <label
    for="form-input"
    class="form-label"
    [ngClass]="{ 'form-label-placeholder': placeholder != ' ' }"
    >{{ label | translate }}
    <span *ngIf="isRequired" class="mandatory-field">*</span>
  </label>
</div>
<div
  *ngIf="
    (inputEmail.dirty || inputEmail.touched || submitted) &&
      inputEmail.errors?.required;
    else validationError
  "
  class="form-validation-errors"
>
  <strong>{{ 'field-required' | translate }}</strong>
</div>
<ng-template #validationError>
  <div
    *ngIf="
      (inputEmail.dirty || inputEmail.touched || submitted) &&
      (errorText || (!inputEmail.errors?.required && !value))
    "
    class="form-validation-errors tst2"
  >
    <strong>{{ errorText | translate }}</strong>
  </div>
</ng-template>
