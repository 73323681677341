import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[numbersOnly]',
})
export class NumberDirective implements OnInit, OnChanges {
  @Input() rangeFrom;
  @Input() rangeTo;
  @Input() submitted = false;

  constructor(
    private eleRef: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.removeError();
    if (this.submitted) this.showValidateError();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.removeError();
    const change = changes['submitted'];

    if (change && change.currentValue) {
      this.checkValidation();
    }
  }

  @HostListener('input', ['$event'])
  handleFocusEvent(event) {
    // this.checkValidation();
    const initalValue = this.eleRef.nativeElement.value;
    this.eleRef.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    this.checkValidation()
    initalValue !== this.eleRef.nativeElement.value
      ? event.stopPropagation()
      : '';
  }

  @HostListener('focusout', ['$event'])
  handleBlurEvent(event) {
    this.eleRef.nativeElement.value.length >= this.rangeFrom &&
    this.eleRef.nativeElement.value.length <= this.rangeTo
      ? this.removeError()
      : this.showValidateError();
  }

  checkValidation() {
    const initalValue = this.eleRef.nativeElement.value;
    this.eleRef.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (
      this.eleRef.nativeElement.value.length >= this.rangeFrom &&
      this.eleRef.nativeElement.value.length <= this.rangeTo
    ) {
      this.removeError();
    } else {
      this.showValidateError();
    }
  }

  showValidateError() {
    const errorSpanId = this.eleRef.nativeElement?.name;
    if (errorSpanId) {
      this.removeError();
      const errorMsg =
        this.eleRef.nativeElement.value == null ||
        this.eleRef.nativeElement.value == ''
          ? this.translate.instant(errorSpanId) +
            ' ' +
            this.translate.instant('is-required')
          : this.translate.instant('enter-valid') +
            ' ' +
            this.translate.instant(errorSpanId);
      const errSpan =
        '<span style="font-size:0.75rem;" class="form-validation-errors" id="' +
        errorSpanId +
        '"><strong>' +
        errorMsg +
        '</strong></span>';
      this.eleRef.nativeElement.parentElement.insertAdjacentHTML(
        'afterend',
        errSpan
      );
    }
  }

  removeError() {
    const errorSpanId = this.eleRef.nativeElement?.name;
    if (errorSpanId) {
      const errorElement = document.getElementById(errorSpanId);
      if (errorElement) errorElement.remove();
    }
  }
}
