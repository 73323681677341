import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ClearMessagingDetails,
  LoginMessaging,
  UserAuthState,
} from '@quipo/quipo-lib';
import { tap } from 'rxjs/operators';
import { MessagingService } from '../services/messaging.service';

export class MessagingStateModel {
  loginDetails: any;
}

@State<MessagingStateModel>({
  name: 'messaging',
  defaults: {
    loginDetails: undefined,
  },
})
@Injectable()
export class MessagingState {
  constructor(
    public store: Store,
    private messagingService: MessagingService
  ) {}

  @Selector()
  static getMessagingLoginDetails(state: MessagingStateModel) {
    return state.loginDetails;
  }

  @Action(LoginMessaging)
  processLogin({ patchState }: StateContext<MessagingStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.messagingService.getMatterMostDetails(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              loginDetails: response.data,
            });
          }
        }
      })
    );
  }
  @Action(ClearMessagingDetails)
  clearDetails({ patchState }: StateContext<MessagingStateModel>) {
    patchState({
      loginDetails: undefined,
    });
  }
}
