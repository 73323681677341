import { Injectable, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class Base64Service {
  constructor(private sanitizer: DomSanitizer) {}

  openFileInNewWindow(base64Data: string, fileType: string, fileName: string) {
    const typeData = this.replaceType(fileType);
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: typeData });

    const fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl, '_blank');
  }

  openFile(base64Data: string, fileType: string, fileName: string) {
    const typeData = this.replaceType(fileType);
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: typeData });

    const fileUrl = URL.createObjectURL(blob);
    window.location.replace(fileUrl);
  }

  replaceType(fileType: string): string {
    switch (fileType) {
      case 'pdf':
        return 'application/pdf';
      case 'txt':
        return 'text/plain';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'doc':
        return 'application/msword';
      case 'png':
        return 'image/png';
      case 'jpeg':
        return 'image/jpeg';
      case 'PNG':
        return 'image/png';
      default:
        return '';
    }
  }
}
