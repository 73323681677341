<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
  >
  <p style="color: white">Processing...</p>
</ngx-spinner>
