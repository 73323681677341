import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhysicianAvailabilityService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getPhysicianAvailability(accessToken, postdata) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/weekly-availability`;

    return this.http.post(url, postdata, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getWaitingList(accessToken, postdata) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/waiting-list`;

    return this.http.post(url, postdata, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getActiveAppointmentType(accessToken, oscarDrId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/active-appointment-types/${oscarDrId}`;

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  addPhysicianAvailability(accessToken, postdata) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/doctor-availability`;

    return this.http.post(url, postdata, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getAvailabilitySlotDetails(accessToken, availabilityId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/doctor-availability/${availabilityId}`;

    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  updateAvailabilitySlotDetails(accessToken, payload) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/doctor-availability/${payload.availabilityId}`;

    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  deleteAvailabilitySlotDetails(accessToken, availabilityId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/doctor-availability/${availabilityId}`;

    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  getAvailabilitybyAppointmentType(accessToken, postdata) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/fetch-doctors`;

    return this.http.post(url, postdata, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
