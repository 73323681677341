import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SweetAlertService } from './sweet-alert-service';
@Injectable({
  providedIn: 'root',
})
export class PWAService {
  deferredPrompt: any;
  isHide = false;
  constructor(
    private swUpdate: SwUpdate,
    private sweetAlert: SweetAlertService
  ) {
    swUpdate.available.subscribe((event) => {
      this.askUserToUpdate();
    });
    console.log('In service', swUpdate);
    this.isHide = JSON.parse(localStorage.getItem('hideInstall'));
    window.addEventListener('beforeinstallprompt', (event) => {
      this.deferredPrompt = event;
      this.promptUserInstall(event);
    });
  }
  checkForUpdates() {
    this.swUpdate.available.subscribe((event) => this.askUserToUpdate());
  }
  askUserToUpdate() {
    console.log('update available');
    const header = 'Quipo app Update';
    const message =
      'A newer version of the app is available. Choose YES to update';
    if (!this.isHide) {
      this.sweetAlert.YesorNo(header, message).then((choice) => {
        if (choice) {
          this.swUpdate.activateUpdate().then(() => document.location.reload());
        } else {
          return;
        }
      });
    }
  }

  promptUserInstall(event) {
    event.preventDefault();
    if (!this.isHide) {
      this.sweetAlert
        .Install(
          'Install Quipo app',
          'Do you want to add Quipo to the home screen? This feature will keep you updated with changes.'
        )
        .then((choice) => {
          if (choice) {
            localStorage.setItem('hideInstall', 'true');
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                console.log('user accepted A2HS prompt');
              } else {
                console.log('user dismissed A2HS prompt');
              }
              this.deferredPrompt = null;
            });
          } else if (choice == false) {
            localStorage.setItem('hideInstall', 'true');
          } else {
            return;
          }
        });
    }
  }
}
