import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class FamilyMemberService {
  constructor(private http: HttpClient) {}
  // }
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getFamilyMembers(accessToken, accountHolderId) {
    const now = moment();
    const today = now.format('YYYY-MM-DD');
    const dateTime = now.format('MM/DD/YYYY HH:mm:ss');
    if (!accountHolderId) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/family-members/${accountHolderId}`;
    return this.http.post(
      url,
      { today: today, dateTime: dateTime },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  postFamilyMember(payload, accessToken) {
    if (!payload) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/family-member`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  patchFamilyMember(payload, accessToken) {
    if (!payload.patientId) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/family-member/${payload.patientId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  deleteFamilyMember(id, accessToken) {
    if (!id) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/family-member/${id}`;

    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  updatePharmacy(payload, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/preferred-pharmacy`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
