<div class="form-div form-icon">
  <quipo-autocomplete
    [(value)]="contactInfo.address"
    [(itemList)]="locationList"
    [(filteredItems)]="locationList"
    [itemName]="label"
    [submitted]="submitted"
    [isRequired]="true"
    [filterType]="'full'"
    (selectionChange)="searchAddress($event)"
    (valueChange)="getListItem($event)"
  ></quipo-autocomplete>
</div>

<form class="form" #registerForm="ngForm">
  <div>
    <div class="form-div">
      <input
        type="text"
        class="form-input"
        placeholder="Vancouver"
        name="city"
        #city="ngModel"
        [required]="required"
        [(ngModel)]="contactInfo.city"
        (focusout)="formatManualAddress(false)"
      />

      <label for="" class="form-label form-label-placeholder">{{
        'city' | translate
      }}
      <span *ngIf="required" class = "mandatory-field">*</span>
    </label>
    </div>
    <div *ngIf="(city.touched || submitted) && city.errors?.required">
      <strong class="form-validation-errors"
        >{{ 'city' | translate }} {{ 'is-required' | translate }}</strong
      >
    </div>
  </div>
  <div>
    <div class="form-div">
      <input
        type="text"
        class="form-input"
        placeholder="Province"
        name="province"
        #province="ngModel"
        [required]="required"
        [(ngModel)]="contactInfo.province"
        (focusout)="formatManualAddress(false)"
      />
      <label for="" class="form-label form-label-placeholder">{{
        'province' | translate
      }}
      <span *ngIf="required" class = "mandatory-field">*</span>
    </label>
    </div>
    <div *ngIf="(province.touched || submitted) && province.errors?.required">
      <strong class="form-validation-errors"
        >{{ 'province' | translate }} {{ 'is-required' | translate }}</strong
      >
    </div>
  </div>
  <div>
    <div class="form-div">
      <input
        type="text"
        class="form-input"
        name="PostalCode"
        placeholder="A1A 1A1"
        #postalcode="ngModel"
        [required]="required"
        [(ngModel)]="contactInfo.postalcode"
        (focusout)="formatManualAddress(false)"
        (change)="changeHandler($event)"
      />
      <label for="" class="form-label form-label-placeholder">{{
        'postal-code' | translate
      }}
      <span *ngIf="required" class = "mandatory-field">*</span>
    </label>
    </div>
    <div
      *ngIf="(postalcode.touched || submitted) && postalcode.errors?.required"
    >
      <strong class="form-validation-errors"
        >{{ 'postal-code' | translate }} {{ 'is-required' | translate }}</strong
      >
    </div>
    <div
      *ngIf="
        contactInfo.postalcode &&
        postalcode.touched &&
        !isZipCodeValid(contactInfo.postalcode)
      "
    >
      <strong class="form-validation-errors">{{
        'enter-valid-postal-code' | translate
      }}</strong>
    </div>
  </div>
</form>
