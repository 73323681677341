import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VirtualCareService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  fetchAppointments(data, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.consultationUrl}/consultation`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  cancelAppointment(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.consultationUrl}/consultation/appointment-status`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  updateAppointmentStatus(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.consultationUrl}/consultation/appointment-status`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  FetchOscarAppointmentDetailByPatientId(
    payload,
    accessToken
  ): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.consultationUrl}/consultation/oscar-appointment/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchAppointmentDetail(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.consultationUrl}/consultation/appointments/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  initTwilio(accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/twilio/init`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  SendEmail(data, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/sendgrid`;
    // console.log('url', url);
    // console.log('data', data);
    // console.log('token', accessToken);
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  SendSMS(data, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/twilio/sms`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  sendEncounder(data, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/encounter-notes`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
