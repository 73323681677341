import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CareTeamService {
  constructor(private http: HttpClient) {}
  // }

  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }
  getcareTeams(accessToken, patientId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/care-teams/${patientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  postCareTeam(payload, accessToken, patientId) {
    if (!payload) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/care-team/${patientId}`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  patchCareTeam(payload, accessToken) {
    if (!payload.uniqueId) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/care-team/${payload.uniqueId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  deleteCareTeam(id, accessToken) {
    if (!id) return;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/care-team/${id}`;
    //  const headers = {
    //    Authorization: `Bearer ${accessToken}`
    //  };
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
