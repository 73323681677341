import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ClearKeycloakStatus, UpdateKeycloakUser } from '../actions';
// import { tap } from 'rxjs/operator';
import {
  ClearPhysicianProfileResponse,
  ClearProfileData,
  FetchPhysicianProfile,
  UpdatePhysicianProfile,
} from '../actions/physician.actions';
import {
  PhysicianProfileDetails,
  PhysicianResponse,
} from '../interfaces/physician.interface';
import { PhysicianService } from '../services/physician.service';
import { UserAuthState } from './user-auth.state';

export class PhysicianStateModel {
  profileData: PhysicianProfileDetails;
  PhysicianResponse: PhysicianResponse;
}

@State<PhysicianStateModel>({
  name: 'physicianDetails',
  defaults: {
    profileData: {
      staffId: null,
      firstName: '',
      lastName: '',
      staffType: '',
      oscarDoctorId: '',
      userId: '',
      title: '',
      qualifications: '',
      provideNumber: '',
      licenseNumber: '',
      languages: '',
      speciality: { itemCode: '', itemName: '' },
      clinicNumber: '',
      cellNumber: '',
      faxNumber: '',
      address: '',
      city: '',
      province: '',
      email: '',
      signature: '',
      createdAt: '',
      updatedAt: '',
    },
    PhysicianResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
  },
})
@Injectable()
export class PhysicianState {
  constructor(
    private store: Store,
    private physicianService: PhysicianService
  ) {}
  @Selector()
  static getPhysicianProfileDetail(state: PhysicianStateModel) {
    return state.profileData;
  }

  @Selector()
  static getPhysicianProfileResponse(state: PhysicianStateModel) {
    return state.PhysicianResponse;
  }

  @Action(FetchPhysicianProfile)
  FetchProfileDetail({ patchState }: StateContext<any>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.physicianService
        .getPhysicianProfileDetails(loginInfo.staffId, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    profileData: response,
                  });
                  return;
                }
              }
              patchState({
                profileData: [],
              });
            }
          })
        );
    }
  }

  @Action(ClearPhysicianProfileResponse)
  ClearCareteamResponse({ patchState }: StateContext<any>) {
    patchState({
      PhysicianResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }
  @Action(ClearProfileData)
  ClearProfileData({ patchState }: StateContext<any>) {
    patchState({
      profileData: undefined,
    });
  }

  @Action(UpdatePhysicianProfile)
  UpdateCareTeam(
    { patchState }: StateContext<PhysicianStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.physicianService
        .patchPhysicianProfileDetails(
          payload,
          loginInfo.staffId,
          loginInfo.token
        )
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (payload.userId) {
                  const userData = {
                    id: payload.userId,
                    obj: {
                      firstName: payload.firstName,
                      lastName: payload.lastName,
                      email: payload.email,
                      username: payload.email,
                    },
                  };
                  this.store.dispatch(new ClearKeycloakStatus());
                  this.store.dispatch(new UpdateKeycloakUser(userData));
                }
                if (response.data) {
                  patchState({
                    PhysicianResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  PhysicianResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                PhysicianResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }
}
