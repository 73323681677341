export class ClearSettingsResponse {
  static readonly type = '[GeneralSettings] Fetch All';
}
export class ClearDoctorsSettings {
  static readonly type = '[DoctorsSettings] Clear All';
}
export class ClearDoctors {
  static readonly type = '[DoctorsSettings] Clear All';
}

export class FetchLookupLocations {
  static readonly type = '[Locations] Fetch All';
  constructor(public payload) {}
}

export class ClearLookupLocationsResponse {
  static readonly type = '[LookupLocations] Clear All';
}

// General Settings
export class FetchGeneralSettings {
  static readonly type = '[GeneralSettings] Fetch All';
}

export class UpdateGeneralSettings {
  static readonly type = '[GeneralSettings] Update';
  constructor(public payload) {}
}

// Location Settings
export class FetchLocationSettings {
  static readonly type = '[LocationSettings] Fetch All';
}

export class UpdateLocationSettings {
  static readonly type = '[LocationSettings] Update';
  constructor(public payload) {}
}

export class AddLocationSettings {
  static readonly type = '[LocationSettings] Add';
  constructor(public payload) {}
}

// Doctor Settings
export class FetchDoctorSettings {
  static readonly type = '[DoctorSettings] Fetch All';
   constructor(public payload) {}
}
export class FetchDoctors {
  static readonly type = '[Doctors] Fetch All';
}
export class AddDoctorSettings {
  static readonly type = '[DoctorSettings] Add';
  constructor(public payload) {}
}

export class UpdateDoctorSettings {
  static readonly type = '[DoctorSettings] Update';
  constructor(public payload) {}
}

// Clinic-Staff Settings
export class FetchClinicStaffSettings {
  static readonly type = '[ClinciStaffSettings] Fetch All';
   constructor(public payload) {}
}

export class AddClinicStaffSettings {
  static readonly type = '[ClinciStaffSettings] Add';
  constructor(public payload) {}
}
export class UpdateClinicStaffSettings {
  static readonly type = '[ClinciStaffSettings] Update';
  constructor(public payload) {}
}

// Clinic-Policies Settings
export class FetchClinicPoliciesSettings {
  static readonly type = '[ClincPoliciesSettings] Fetch All';
}

export class AddClinicPoliciesSettings {
  static readonly type = '[ClinciPoliciesSettings] Add';
  constructor(public payload) {}
}
export class UpdateClinicPoliciesSettings {
  static readonly type = '[ClinciPoliciesSettings] Update';
  constructor(public payload) {}
}

// Workflow-Policies Settings
export class FetchWorkflowPoliciesSettings {
  static readonly type = '[WorkflowPoliciesSettings] Fetch All';
}
export class AddWorkflowPoliciesSettings {
  static readonly type = '[WorkflowPoliciesSettings] Add';
  constructor(public payload) {}
}

export class UpdateWorkflowPoliciesSettings {
  static readonly type = '[WorkflowPoliciesSettings] Update';
  constructor(public payload) {}
}

// Timed Tasks

export class FetchTimedTask{
  static readonly type = '[Timed tasks] Fetch';
}

export class SetTimedTasks{
  static readonly type = '[Timed Task] Set';
  constructor(public payload){}
}
