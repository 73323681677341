import { Pipe, PipeTransform } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
//to implement date
import { DatePipe } from '@angular/common';
import localEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeUr from '@angular/common/locales/ur';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeFa from '@angular/common/locales/fa';
import localeDe from '@angular/common/locales/de';
import localeZh from '@angular/common/locales/zh';
registerLocaleData(localeFr, 'fr');
registerLocaleData(localEn, 'en');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeUr, 'ur');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeFa, 'fa');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeZh, 'zh-CHS');

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, pattern: string = 'mediumDate'): any {
    const datePipe: DatePipe = new DatePipe(this.translate.currentLang);
    return datePipe.transform(value, pattern);
  }
}
