import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ResoucrcesResponse } from '../interfaces/reources.interface';
import {
  ClearResourcesList,
  ClearResourcesResponse,
  FetchAllResources,
  FetchFavouriteResources,
  FetchResourceDetail,
  PatchResource,
  PatchResourceToFavourite,
  SaveResource,
} from '../actions/resources.action';
import { UserAuthState } from './user-auth.state';
import { ResourcesService } from '../services/resources.service';
import { FamilyMemberState } from './family-member.state';

export class ResourcesStateModel {
  resource: any;
  resourcesList: any[];
  favouriteResourcesList: any[];
  resourcesResponse: ResoucrcesResponse;
}

@State<ResourcesStateModel>({
  name: 'resources',
  defaults: {
    resource: undefined,
    resourcesList: [],
    favouriteResourcesList: [],
    resourcesResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
  },
})
@Injectable()
export class ResourceState {
  constructor(
    private store: Store,
    private resourcesService: ResourcesService
  ) {}

  @Action(ClearResourcesResponse)
  ClearCareteamResponse({ patchState }: StateContext<ResourcesStateModel>) {
    patchState({
      resourcesResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }

  @Action(ClearResourcesList)
  ClearResourceList({ patchState }: StateContext<ResourcesStateModel>) {
    patchState({
      resourcesList: undefined,
    });
  }

  @Selector()
  static getResourcesResponse(state: ResourcesStateModel) {
    return state.resourcesResponse;
  }

  // All Resources List detail
  @Selector()
  static getResourcesListDetails(state: ResourcesStateModel) {
    return state.resourcesList;
  }

  @Selector()
  static gerResourceById(state: ResourcesStateModel) {
    return state.resource;
  }

  // All Favouritte Resources List
  @Selector()
  static getFavouriteResources(state: ResourcesStateModel) {
    return state.favouriteResourcesList;
  }

  @Action(FetchAllResources)
  FetchAllResources({ patchState }: StateContext<ResourcesStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.resourcesService
        .getResourcesListDetails(loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    resourcesList: response.data,
                  });
                  return;
                }
              }
              patchState({
                resourcesList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchResourceDetail)
  FetchResource(
    { patchState }: StateContext<ResourcesStateModel>,
    { resourceId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.resourcesService
        .getResourceDetailById(loginInfo.token, resourceId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    resource: response.data,
                  });
                  return;
                }
              }
              patchState({
                resource: undefined,
              });
            }
          })
        );
    }
  }

  @Action(FetchFavouriteResources)
  FetchFavouriteResourcesList({
    patchState,
  }: StateContext<ResourcesStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token) {
      return this.resourcesService
        .getFavouriteResourcesList(loginInfo.token, patient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    favouriteResourcesList: response.data,
                  });
                  return;
                }
              }
              patchState({
                favouriteResourcesList: [],
              });
            }
          })
        );
    }
  }

  @Action(PatchResourceToFavourite)
  PatchResourceToFavourite(
    { patchState }: StateContext<ResourcesStateModel>,
    { favouriteStatus, resourceId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    let payload = {
      resourceId: resourceId,
      enable: favouriteStatus,
    };
    if (loginInfo && loginInfo.token) {
      return this.resourcesService
        .patchResourceToFavourite(loginInfo.token, patient.id, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                patchState({
                  resourcesResponse: {
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
                this.store.dispatch(new FetchFavouriteResources());
              } else {
                patchState({
                  resourcesResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                resourcesResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(SaveResource)
  SaveResource(
    { patchState }: StateContext<ResourcesStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );

    if (loginInfo && loginInfo.token) {
      return this.resourcesService.saveResource(loginInfo.token, payload).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                resourcesResponse: {
                  statusCode: 200,
                  errorMessage: undefined,
                },
              });
            } else {
              patchState({
                resourcesResponse: {
                  statusCode: response.statusCode,
                  errorMessage: response.message ? response.message : 'Error',
                },
              });
            }
          } else {
            patchState({
              resourcesResponse: {
                statusCode: 0,
                errorMessage: undefined,
              },
            });
          }
        })
      );
    }
  }

  @Action(PatchResource)
  PatchResource(
    { patchState }: StateContext<ResourcesStateModel>,
    { resourceId, payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );

    if (loginInfo && loginInfo.token) {
      return this.resourcesService
        .patchResource(loginInfo.token, resourceId, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                patchState({
                  resourcesResponse: {
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
              } else {
                patchState({
                  resourcesResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                resourcesResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }
}
