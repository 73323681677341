import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  constructor(private http: HttpClient) {}
  // Get all Resource list details
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getResourcesListDetails(accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/resources`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Get single resource detail by resourceId
  getResourceDetailById(accessToken, resourceId) {
    const userToken = this.getUserTocken();
    const url = `${environment.adminUrl}/resource/${resourceId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Fetch favourite resources list
  getFavouriteResourcesList(accessToken, patientId) {
    const userToken = this.getUserTocken();
    const url = `${environment.adminUrl}/patient-favourite-resources/${patientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Patch resource
  patchResourceToFavourite(accessToken, patientId, payload) {
    const userToken = this.getUserTocken();
    const url = `${environment.adminUrl}/patient-favourite-resources/${patientId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Save resource
  saveResource(accessToken, payload) {
    const userToken = this.getUserTocken();
    const url = `${environment.adminUrl}/resource`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Patch resource
  patchResource(accessToken, resourceId, payload) {
    const userToken = this.getUserTocken();
    const url = `${environment.adminUrl}/resource/${resourceId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
