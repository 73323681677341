import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { debounceTime, tap } from 'rxjs/operators';
import {
  FetchAllFamilyMembers,
  // FetchFamilyMember,
  SetSelectedPatient,
  AddFamilyMember,
  UpdateFamilyMember,
  UpdatePharmacy,
  ClearFamilyMemberResponse,
  ClearFamilyMembers,
  DeleteFamilyMember,
} from '../actions/family-member.action';
import { FamilyMemberService } from '../services/family-member.service';
import {
  FamilyMemberDetails,
  FamilyMemberResponse,
} from '../interfaces/family-member.interface';
import { Store } from '@ngxs/store';
import { UserAuthState } from './user-auth.state';
import {
  ClearKeycloakStatus,
  ClearLoginDetals,
  FetchPatientDeleteRequests,
  UpdateKeycloakUser,
} from '../actions';
import { Router } from '@angular/router';

export class FamilyMemberStateModel {
  familymembers: FamilyMemberDetails[];
  familymemberResponse: FamilyMemberResponse;
  selectedPatient: any;
}
@State<FamilyMemberStateModel>({
  name: 'familyMembers',
  defaults: {
    selectedPatient: undefined,
    familymembers: [],
    familymemberResponse: {
      errorMessage: undefined,
      statusCode: 0,
      patientId: undefined,
    },
  },
})
@Injectable()
export class FamilyMemberState {
  constructor(
    private familyMemberService: FamilyMemberService,
    private store: Store,
    public router: Router
  ) {}

  @Selector()
  static getFamilyMemberDetails(state: FamilyMemberStateModel) {
    return state.familymembers;
  }
  @Selector()
  static getfamilyMemberResponse(state: FamilyMemberStateModel) {
    return state.familymemberResponse;
  }

  @Selector()
  static getSelectedPatient(state: FamilyMemberStateModel) {
    return state.selectedPatient;
  }

  @Action(ClearFamilyMemberResponse)
  ClearfamilyMemberResponse({
    patchState,
  }: StateContext<FamilyMemberStateModel>) {
    patchState({
      familymemberResponse: {
        errorMessage: undefined,
        statusCode: 0,
        patientId: undefined,
      },
    });
  }

  @Action(SetSelectedPatient)
  SetSelectedPatient(
    { patchState }: StateContext<FamilyMemberStateModel>,
    { payload }: any
  ) {
    patchState({
      selectedPatient: payload,
    });
  }

  @Action(FetchAllFamilyMembers)
  FetchAllFamilyMembers({ patchState }: StateContext<FamilyMemberStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token && loginInfo.patientId) {
      return this.familyMemberService
        .getFamilyMembers(loginInfo.token, loginInfo?.patientId)
        .pipe(
          debounceTime(1000),
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  // response.data.isNewPaientQuestonnaireCompleted = response.data
                  //   .newPaientQuestonnaire
                  //   ? true
                  //   : false;
                  if (
                    !response.data ||
                    (response.data && response.data.length == 0)
                  ) {
                    this.store.dispatch(new ClearLoginDetals());
                    const pwa = localStorage.getItem('hideInstall');
                    localStorage.clear();
                    if (pwa) localStorage.setItem('hideInstall', pwa);
                    this.router.navigate(['/']);
                  }

                  patchState({
                    familymembers: response.data,
                  });
                  if (response.data.length > 0) {
                  }
                  return;
                }
              }
              patchState({
                familymembers: [],
              });
            }
          })
        );
    }
  }

  @Action(AddFamilyMember)
  AddFamilyMember(
    { patchState }: StateContext<FamilyMemberStateModel>,
    { payload }: AddFamilyMember
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.familyMemberService
        .postFamilyMember(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    familymemberResponse: {
                      statusCode: 200,
                      patientId: response.data.patientId,
                      errorMessage: undefined,
                    },
                    selectedPatient: response.data,
                  });
                }
              } else {
                patchState({
                  familymemberResponse: {
                    statusCode: response.statusCode,
                    patientId: '',
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                familymemberResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                  patientId: undefined,
                },
              });
            }
          })
        );
    }
  }
  @Action(UpdateFamilyMember)
  UpdateFamilyMember(
    { patchState }: StateContext<FamilyMemberStateModel>,
    { payload }: UpdateFamilyMember
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.familyMemberService
        .patchFamilyMember({ ...payload, userIdOnAction: loginInfo.userId }, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                const selectedPatient: any = this.store.selectSnapshot(
                  FamilyMemberState.getSelectedPatient
                );
                if (
                  selectedPatient &&
                  selectedPatient.patientId == payload.patientId &&
                  selectedPatient.userId
                ) {
                  const userData = {
                    id: selectedPatient.userId,
                    obj: {
                      firstName: payload.firstName,
                      lastName: payload.lastName,
                      email: payload.email,
                      username: payload.email,
                    },
                  };
                  this.store.dispatch(new ClearKeycloakStatus());
                  this.store.dispatch(new UpdateKeycloakUser(userData));
                } else if (payload.userId) {
                  const userData = {
                    id: payload.userId,
                    obj: {
                      firstName: payload.firstName,
                      lastName: payload.lastName,
                      email: payload.email,
                      username: payload.email,
                    },
                  };
                  this.store.dispatch(new ClearKeycloakStatus());
                  this.store.dispatch(new UpdateKeycloakUser(userData));
                }

                if (response.data) {
                  patchState({
                    familymemberResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                      patientId: undefined,
                    },
                  });

                  this.store.dispatch(new FetchAllFamilyMembers());
                }
              } else {
                patchState({
                  familymemberResponse: {
                    patientId: undefined,
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                familymemberResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                  patientId: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(ClearFamilyMembers)
  clearFamily({ patchState }: StateContext<FamilyMemberStateModel>) {
    patchState({
      familymembers: undefined,
      familymemberResponse: undefined,
      selectedPatient: undefined,
    });
  }

  @Action(UpdatePharmacy)
  UpdatePharmacy(
    { patchState }: StateContext<FamilyMemberStateModel>,
    { payload }: UpdatePharmacy
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.familyMemberService
        .updatePharmacy(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
            }
          })
        );
    }
  }

  @Action(DeleteFamilyMember)
  DeleteFamilyMember(
    { patchState }: StateContext<FamilyMemberStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.familyMemberService
        .deleteFamilyMember(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode == 200) {
              this.store.dispatch(new FetchPatientDeleteRequests());
            }
          })
        );
    }
  }
}
