import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByNameDate',
})
export class OrderByNameDatePipe implements PipeTransform {
  transform(array: any, priorField1: string, priorField2): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[priorField1] < b[priorField1]) {
        return -1;
      } else if (a[priorField1] > b[priorField1]) {
        return 1;
      } else {
        return <any>new Date(b[priorField2]) - <any>new Date(a[priorField2]);
      }
    });
    return array;
  }
}
