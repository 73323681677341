import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DateSelectorComponent } from './date-selector.component';
import { CoreModule } from '../../../core/core.module';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  declarations: [DateSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    DpDatePickerModule,
    CoreModule,
    AngularMyDatePickerModule,
  ],
  exports: [DateSelectorComponent],
})
export class DateSelectorModule {}
