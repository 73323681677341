import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  DoCheck,
  HostListener,
} from '@angular/core';
import { AsYouType, isValidNumber, CountryCode } from 'libphonenumber-js';

@Component({
  selector: 'quipo-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, DoCheck {
  @Input() icon;
  @Input() showSelection;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() value: string;
  @Input() width: string;
  @Output() valueChange = new EventEmitter();

  validPhone: boolean;
  showImgList: boolean;
  // phone: string;
  selectedType: any;

  phoneTypeList = [
    { value: '1', name: 'Cell', image: 'assets/images/mobile-phone.svg' },
    {
      value: '2',
      name: 'Landline',
      image: 'assets/images/land-phone.svg',
      width: '1.25em',
    },
  ];

  @HostListener('document:click', ['$event'])
  clicked($event) {
    if (
      $event.target.className.includes('type-list') ||
      $event.target.className.includes('phone')
    )
      this.showImgList = this.showImgList ? true : false;
    else this.showImgList = false;
  }

  constructor() {
    this.required = false;
    this.submitted = false;
    this.validPhone = true;
    this.selectedType = this.phoneTypeList[0];
    this.showImgList = false;
  }

  ngOnInit() {
    this.setIntialData();
  }

  ngDoCheck() {
    if (this.value?.includes(',')) {
      this.setIntialData();
    }
  }

  setIntialData() {
    if (this.value) {
      const splittedVal = this.value.split(',');
      this.value = splittedVal[0];
      const type = splittedVal[1] ? splittedVal[1] : '1';
      if (type) {
        this.phoneTypeList.map((item) => {
          if (item.value == type) {
            this.selectedType = item;
          }
        });
      } else this.selectedType = this.phoneTypeList[0];
    }
  }
  // keyPressAlphaNumeric(event) {
  //   var inp = String.fromCharCode(event.keyCode);

  //   if (/[a-zA-Z0-9]/.test(inp)) {
  //     return true;
  //   } else {
  //     event.preventDefault();
  //     return false;
  //   }
  // }
  keyPress(event) {
    const inp = String.fromCharCode(event.keyCode);
    // if (/[a-zA-Z]/.test(inp)) {
    //   return false;
    // } else {
    //   return true;
    // }
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      return false;
    }
  }

  emitPhone(event) {
    const pattern = /^[0-9-()+\s]+$/;
    if (event) {
      event = event.trim();
      this.validPhone = true;
      const mappedPhone: CountryCode = <CountryCode>'CA';
      const formatted = new AsYouType(mappedPhone).input(event);
      this.validPhone = isValidNumber(formatted, mappedPhone);
      if (!pattern.test(event)) {
        this.value = '';
        setTimeout(() => {
          this.value = event.replace(/[^0-9-()\s]/g, '');
          this.valueChange.emit(this.value);
        });
      } else if (this.validPhone) {
        this.value = '';
        setTimeout(() => {
          this.value = formatted;
          this.valueChange.emit(this.value + ',' + this.selectedType.value);
        });
      }
    } else {
      this.validPhone = true;
      this.valueChange.emit(event);
    }
  }

  getWidth() {
    if (this.width) {
      return this.width;
    }
  }

  showList() {
    this.showImgList = !this.showImgList;
  }

  selectPhoneType(type) {
    this.selectedType = type;
    this.showList();
    const phone = this.value ? this.value : '';
    this.valueChange.emit(phone + ',' + this.selectedType.value);
  }
}
