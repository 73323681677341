export class AddQuestionnaire {
  static readonly type = '[Questionnaire] Add';
  constructor(public payload: any) {}
}

export class UpdateQuestionnaire {
  static readonly type = '[Questionnaire] Update';
  constructor(public payload: any) {}
}
export class FetchReasonQuestionnaire {
  static readonly type = '[ReasonQuestionnaire] Fetch';
  constructor(public payload: any) {}
}

export class EnableQuestionnaire {
  static readonly type = '[Questionnaire] Enable';
  constructor(public payload: any) {}
}
export class FetchQuestionnaire {
  static readonly type = '[Questionnaire] Fetch';
  constructor(public payload: any) {}
}

export class AddPatientResponse {
  static readonly type = '[Questionnaire Response] Add';
  constructor(public payload: any) {}
}
export class UpdatePatientResponse {
  static readonly type = '[Questionnaire Response] Update';
  constructor(public payload: any) {}
}
export class ClearAnswerResponse {
  static readonly type = '[Questionnaire Response] Clear';
}

export class ClearSkipNewPatientQuestionnaire {
  static readonly type = '[Skip Questionnaire Response] Clear';
}

export class ClearQuestionnaire {
  static readonly type = '[Questionnaire] Clear';
}

export class SkipNewPatientQuestionnaire {
  static readonly type = '[Questionnaire Response] Skip';
  constructor(public payload: any) {}
}
