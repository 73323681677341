import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  LookUpData,
  ListData,
} from '../../../core/interfaces/common.interface';
import { CommonState } from '../../../core/state/common.state';
import { Observable } from 'rxjs';
import { FetchData } from '../../../core/actions/common.actions';
import { BaseComponent } from '../../../core/base';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'quipo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Input() isRequired: boolean;
  @Input() isDisable: boolean;
  @Input() label: string;
  @Input() lookupItem: string;
  @Input() lookupList: any;
  @Input() width: string;
  @Input() value: string;
  @Input() generalPractitionerFlag;
  @Input() preventSelection = false;
  @Input() selectLocationOptionDisable = false;
  @Input() isBookedDetailSubmit = false;
  @Output() valueChange = new EventEmitter();
  @Output() ChangeEvent = new EventEmitter();
  selectedValue = '';
  prevValue = '';
  selectedItem: any = undefined;
  @ViewChild('dropdownSelect', { static: false }) dropdownSelect!: ElementRef;
  @Select(CommonState.getLookUp) lookUpData$: Observable<LookUpData[]>;

  lookupData: ListData[];

  constructor(store: Store, public translate: TranslateService) {
    super(store);
  }

  ngOnInit() {
    this.getLookUp();
  }

  getLookUp() {
    if (
      this.lookupItem == 'other' ||
      this.lookupItem == 'bookAppointment' ||
      this.lookupItem == 'appointmentLocation' ||
      this.lookupItem == 'doctors' ||
      this.lookupItem == 'default'
    ) {
      this.lookupData = this.lookupList;
      if (
        this.value &&
        this.value['itemCode'] &&
        this.lookupData &&
        this.lookupData.length > 0
      ) {
        this.selectedItem = this.lookupData.find(
          (Item) => Item.itemCode === this.value['itemCode']
        );
      } else {
        this.selectedItem = null;
      }
      this.loadLookupData();
    } else {
      this.store.dispatch(new FetchData(this.lookupItem));
      this.lookUpData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data && (!this.lookupData || this.lookupData.length == 0)) {
          const index = data.findIndex((x) => x.lookupCode === this.lookupItem);
          this.lookupData = index >= 0 ? data[index]?.lookupValues : [];
          if (
            this.value &&
            this.value['itemCode'] &&
            (!this.selectedItem ||
              (this.selectedItem &&
                Object.keys(this.selectedItem).length === 0))
          ) {
            this.selectedItem = this.lookupData.find(
              (Item) => Item.itemCode === this.value['itemCode']
            );
          }
          this.loadLookupData();
        }
      });
    }
  }

  ngOnChanges() {
    this.getLookUp();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.selectedValue) {
        this.prevValue = this.selectedValue;
        this.change(this.selectedValue);
      }
    }, 1000);
  }

  loadLookupData() {
    if (this.lookupData && this.lookupData.length > 0) {
      this.lookupData.sort(this.dynamicSort('itemName'));
      if (
        this.lookupItem == 'LANGUAGE' &&
        this.selectedItem &&
        Object.keys(this.selectedItem).length === 0
      ) {
        this.selectedItem = this.lookupData.find(
          (Item) => Item.itemCode === this.translate.currentLang
        );
      } else if (
        (this.selectedItem && Object.keys(this.selectedItem).length === 0) ||
        !this.selectedItem
      ) {
        if (this.lookupItem == 'RELATIONSHIP') {
          const index = this.lookupData.findIndex((x) => x.itemCode === '1');
          this.selectedItem = this.lookupData[index];
        }
        else if (this.lookupItem == 'doctors') this.selectedItem = undefined;
        else if (
          this.lookupItem != 'bookAppointment' &&
          this.lookupItem != 'default' &&
          this.lookupItem != 'appointmentLocation'
        )
          this.selectedItem = this.lookupData[0];

        this.selectedValue = this.selectedItem?.itemCode;
      } else {
        this.selectedValue = this.selectedItem?.itemCode;
      }
    } else {
      this.selectedItem = {};
      this.selectedValue = null;
    }
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  onChangeSelection(event) {
    if (this.selectedValue) {
      this.selectedItem = this.lookupData.find(
        (Item) => Item.itemCode == this.selectedValue
      );
    } else {
      this.selectedItem = {};
      this.value = null;
    }
    if (this.lookupItem == 'LANGUAGE' && this.value)
      this.translate.use(this.selectedValue);
  }

  change(newValue) {
    if (!this.preventSelection) {
      const selectedItem = this.lookupData.find(
        (Item) => Item.itemCode == newValue
      );
      if (selectedItem) this.valueChange.emit(selectedItem);
      this.ChangeEvent.emit(selectedItem);
    }
  }
  preventClick() {
    if (this.preventSelection) {
      const selectedItem = this.lookupData.find(
        (Item) => Item.itemCode == this.prevValue
      );
      if (selectedItem) this.valueChange.emit(selectedItem);
      this.ChangeEvent.emit(selectedItem);
    }
  }

  getWidth() {
    if (this.width) {
      return this.width;
    }
  }
}
