import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  FetchFile,
  ClearDocsPath,
  ClearImageData,
} from '../../../core/actions';
import { CommonState } from '../../../core/state/common.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../core/base/base-component';

@Component({
  selector: 'quipo-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent extends BaseComponent implements OnInit {
  imageDetails: any;

  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  @Output() upload = new EventEmitter();

  @Input() defaultImage = 'assets/images/def-user.png';

  @Select(CommonState.getEncodedData) encodedImage$: Observable<any>;

  imageURL: any = 'assets/images/def-user.png';
  showSizeErr: boolean;
  showTypeErr: boolean;

  constructor(public store: Store) {
    super(store);
    this.store.dispatch(new ClearImageData());

    this.encodedImage$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (
        data &&
        data.image &&
        !data.image.base64Content?.includes('undefined')
      )
        this.imageURL = data.image.base64Content;
      else this.imageURL = 'assets/images/def-user.png';
    });
  }

  ngOnInit() {
    this.showSizeErr = false;
    this.showTypeErr = false;
    if (this.value && this.value.includes('https:'))
      this.store.dispatch(new FetchFile({ url: this.value }));
  }

  change(newValue) {
    this.valueChange.emit(newValue);
  }

  uploadImage(event) {
    const files = event.target?.files;
    this.showTypeErr = !files[0].type.includes('image');
    this.showSizeErr = files[0].size / 1024 >= 1024;
    if (files && !this.showTypeErr && !this.showSizeErr) {
      this.showSizeErr = false;
      this.upload.emit(files[0]);
      const reader = new FileReader();
      this.imageDetails = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.value = reader.result;
        this.imageURL = this.value;
        this.valueChange.emit(files[0]);
      };
    }
    // else {
    //   this.showSizeErr = true;
    // }
  }
}
