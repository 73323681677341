import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RadioButtonComponent } from './radio-button.component';
import { CoreModule } from '../../../core/core.module';
@NgModule({
  declarations: [RadioButtonComponent],
  imports: [CommonModule, FormsModule, CoreModule],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
