import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor() {}

  confirm(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          html: message,
          // text: message,
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  alert(title, message, buttonCaption) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          showCancelButton: false,
          confirmButtonText: buttonCaption,
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  simpleAlert(title, message, icon = false) {
    return new Promise((resolve, reject) => {
      if (icon) {
        swal
          .fire({
            title: title,
            text: message,
            position: 'center',
            backdrop: `rgba(128,128,128,0.4)`,
            imageUrl: 'assets/images/quipo-logo-blue.png',
          })
          .then((result) => {
            resolve(result.value);
          });
      } else
        swal
          .fire({
            title: title,
            text: message,
            position: 'center',
            backdrop: `rgba(128,128,128,0.4)`,
          })
          .then((result) => {
            resolve(result.value);
          });
      {
      }
    });
  }

  alertWithHtmlContent(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          html: message,
          title: title,
          position: 'center',
          backdrop: `rgba(128,128,128,0.4)`,
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  // emailVerificationAlert(title, message, emailAddress) {
  //   return new Promise((resolve, reject) => {
  //     swal
  //       .fire({
  //         html: message,
  //         title: title,
  //         position: 'center',
  //         backdrop: `rgba(128,128,128,0.4)`,
  //       })
  //       .then((result) => {
  //         resolve(result.value);
  //       });
  //   });
  // }
  // sendVerificationMail() {
  //   alert('tst');
  // }
  toast(icon, message) {
    const Toast = swal.mixin({
      toast: true,
      position: 'top-start',
      showConfirmButton: false,
      animation: false,
      timer: 7000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer);
        toast.addEventListener('mouseleave', swal.resumeTimer);
      },
    });
    Toast.fire({
      title: message,
    });
  }
  Install(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Install',
          cancelButtonText: 'Not now',
          denyButtonText: 'Never',
          imageUrl: 'assets/images/quipo-logo-blue.png',
          showCloseButton: true,
          focusConfirm: true,
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  YesorNo(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          imageUrl: 'assets/images/quipo-logo-blue.png',
          showCloseButton: true,
          focusConfirm: true,
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
}
