import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
} from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsModule } from '@ngxs/store';
import { HttpClientModule } from '@angular/common/http';
import {
  NgxsStoragePluginModule,
  StorageOption,
  StorageEngine,
  STORAGE_ENGINE,
} from '@ngxs/storage-plugin';
import {
  UserAuthState,
  CareTeamState,
  CommonState,
  AppointmentState,
  QuestionnaireState,
  PhysicianState,
  VirtualCareState,
  PWAService,
  PhysicianAvailabilityState,
  FamilyMemberState,
  SettingState,
  HealthSummaryState,
  ResourceState,
  BillingState,
  TrackHealthState,
  PatientsState,
  MessagingState,
  TimerState,
} from '@quipo/quipo-lib';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { AuthGuard } from './shared/guards/auth.guard';
// import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { DownloadFileComponent } from './download-file/download-file.component';
// import { registerCustomComponent } from './features/patient/modules/questionnaire/components/custom-iterable/custom-iterable.formio';
// import { FormioModule } from 'angular-formio';

export class MyStorageEngine implements StorageEngine {
  length: number;
  removeItem(key: string): void {
    const userToken = sessionStorage.getItem('loggedUserToken');
    if (userToken) sessionStorage.removeItem(key);
    else localStorage.removeItem(key);
  }
  clear(): void {
    const userToken = sessionStorage.getItem('loggedUserToken');
    if (userToken) sessionStorage.clear();
    else localStorage.clear();
  }
  getItem(name) {
    const userToken = sessionStorage.getItem('loggedUserToken');
    if (userToken) return sessionStorage.getItem(name);
    else return localStorage.getItem(name);
  }

  setItem(key, value) {
    const userToken = sessionStorage.getItem('loggedUserToken');
    if (userToken) return sessionStorage.setItem(key, value);
    else return localStorage.setItem(key, value);
  }
}

@NgModule({
  declarations: [AppComponent, DownloadFileComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('../ngsw-worker.js', {
      enabled: environment.production,
    }),
    //ServiceWorkerModule.register('./ngsw-worker.js'),

    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([
      UserAuthState,
      CareTeamState,
      CommonState,
      QuestionnaireState,
      AppointmentState,
      PhysicianState,
      PhysicianAvailabilityState,
      VirtualCareState,
      FamilyMemberState,
      SettingState,
      HealthSummaryState,
      ResourceState,
      BillingState,
      TrackHealthState,
      PatientsState,
      MessagingState,
      TimerState,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    // FormioModule,
  ],
  exports: [],
  providers: [
    {
      provide: STORAGE_ENGINE,
      useClass: MyStorageEngine,
    },
    PWAService,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: true }),
    },
    AuthGuard,
    GoogleAnalyticsService,
  ],

  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  // constructor(injector: Injector) {
  //   registerCustomComponent(injector);
  // }
}
