<div class="form-div">
  <label class="form-label form-label-placeholder" *ngIf="label"
    >{{ label | translate }}
    <span *ngIf="isRequired" class="mandatory-field">*</span>
  </label>
  <select
    class="classic"
    [ngStyle]="{ width: getWidth() }"
    (change)="onChangeSelection($event)"
    [(ngModel)]="
      lookupItem == 'appointmentLocation'
        ? generalPractitionerFlag
          ? selectedValue
          : isBookedDetailSubmit
          ? selectedValue
            ? selectedValue
            : value
          : ''
        : selectedValue
    "
    (ngModelChange)="change($event)"
    (click)="preventClick()"
    [disabled]="isDisable"
    #dropdownSelect="ngModel"
  >
    <ng-container *ngIf="lookupData?.length > 0; else noData">
      <option
        *ngIf="lookupItem == 'default'"
        [disabled]="selectedValue"
        value="undefined"
      >
        Select
      </option>
      <option
        *ngIf="lookupItem == 'doctors'"
        [disabled]="selectedValue"
        value="undefined"
      >
        Please select a practitioner
      </option>

      <option
        value=""
        *ngIf="lookupItem == 'appointmentLocation'"
        [disabled]="selectLocationOptionDisable || selectedValue"
      >
        Please select a Location
      </option>

      <option
        selected
        *ngIf="lookupItem == 'bookAppointment'"
        [disabled]="selectedValue"
      >
        Please select a Visit Type
      </option>
      <option
        *ngFor="let item of lookupData | orderBy: 'itemName'; let i = index"
        [ngValue]="item?.itemCode"
        [selected]="item?.itemCode == selectedValue"
        [disabled]="preventSelection ? item?.itemCode != selectedValue : false"
      >
        {{ item?.itemName | translate }}
      </option>
    </ng-container>
    <ng-template #noData>
      <option disabled>Not Available</option>
    </ng-template>
  </select>
</div>
