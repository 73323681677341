import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../../../core/core.module';
import { ImageInputComponent } from './image-input.component';

@NgModule({
  declarations: [ImageInputComponent],
  imports: [CommonModule, FormsModule, CoreModule],
  exports: [ImageInputComponent],
})
export class ImageInputModule {}
