export class FetchAllAvailability {
  static readonly type = '[Availability] Fetch All';
  constructor(public payload) { }
}
export class FetchAllWaitingList {
  static readonly type = '[WaitingList] Fetch All';
  constructor(public payload) { }
}
export class AddAvailability {
  static readonly type = '[AddAvailability] Add';
  constructor(public payload) { }
}

export class GetActiveAppointmentType {
  static readonly type = '[Get Availability] Only Acitive';
  constructor(public payload) { }
}

export class ClearAvailabilityResponse {
  static readonly type = '[Clear Availability] Clear All';
}
export class ClearAvailabilitySlotResponse {
  static readonly type = '[Clear Availability] Clear All';
}

export class FetchAvailabilitySlotDetails {
  static readonly type = '[Availability] Fetch Slot Detail';
  constructor(public payload) { }
}

export class UpdateAvailabilitySlot {
  static readonly type = '[Availability] Update Slot Detail';
  constructor(public payload) { }
}

export class DeleteAvailabilitySlot {
  static readonly type = '[Availability] Delete';
  constructor(public availabilityId) { }
}
export class FetchAvailabilityByAppointmentType {
  static readonly type = '[Availability] AppointmentType';
  constructor(public payload: any) { }
}
export class ClearAvailabilityByAppointmentType {
  static readonly type = '[Availability] Clear';
}
export class ClearAvailabilityData {
  static readonly type = '[Availability Data] Clear';
}
