import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../../../core/core.module';
import { SwitchComponent } from './switch.component';
@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, FormsModule, CoreModule],
  exports: [SwitchComponent],
})
export class SwitchModule {}
