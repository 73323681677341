export class FetchHealthRecord {
  static readonly type = '[TrackHealth] Fetch';
  constructor(public payload: any) {}
}
export class FetchRecentHealthRecord {
  static readonly type = '[TrackRecentHealth] Fetch';
}

export class FetchLowestHealthRecord {
  static readonly type = '[TrackLowestRecord] Fetch';
}

export class AddHealthRecord {
  static readonly type = '[TrackHealth] Add';
  constructor(public payload: any) {}
}
export class ClearRecord {
  static readonly type = '[TrackHealth] Clear';
}
