import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackHealthService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  fetchHealthStatus(accessToken, data): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/health-tracker`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchRecentHealthStatus(accessToken, id): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/recent-health-record/${id}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchLowestHealthStatus(accessToken, id): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/low-health-record/${id}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  addHealthStatus(accessToken, data, patientId): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/health-tracker/${patientId}`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
