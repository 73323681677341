import { Directive, ElementRef, Input, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[quipoTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input('quipoTooltip') tooltipText: string = ''; // Tooltip text
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'bottom'; // Default position
  @Input() tooltipOffset: number = 10; // Offset for positioning
  tooltipElement: HTMLElement | null = null;
  routerSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    // Listen to route changes and hide tooltip
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideTooltip(); // Hide the tooltip on route change
      }
    });
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    // if (this.tooltipElement) {
    //     this.hideTooltip();
    // }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    if (!this.tooltipElement) {
      if(!this.isMobileDevice){
      this.showTooltip();
    }
    }
    if (this.tooltipElement) {
        const offset = this.tooltipOffset;
  
        switch (this.tooltipPosition) {
          case 'top':
            this.renderer.setStyle(this.tooltipElement, 'top', `${event.clientY - this.tooltipElement.offsetHeight - offset}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${event.clientX}px`);
            break;
          case 'bottom':
            this.renderer.setStyle(this.tooltipElement, 'top', `${event.clientY + offset + 10}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${event.clientX}px`);
            break;
          case 'left':
            this.renderer.setStyle(this.tooltipElement, 'top', `${event.clientY}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${event.clientX - this.tooltipElement.offsetWidth - offset}px`);
            break;
          case 'right':
            this.renderer.setStyle(this.tooltipElement, 'top', `${event.clientY}px`);
            this.renderer.setStyle(this.tooltipElement, 'left', `${event.clientX + offset}px`);
            break;
        }
      }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  @HostListener('click') onClick() {
    this.hideTooltip(); // Hide tooltip on click
  }

  get isMobileDevice() {
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;
    const useragent = navigator.userAgent;
    const phoneordesktop = regex.test(useragent);
    // if (window.screen.width < 800) {
    if (window.screen.width < 800 && phoneordesktop == true) {
      // this.isMobileDevice = true;
      return true;
    } else {
      return false;
      // this.isMobileDevice = false;
    }
  }

  showTooltip() {
    this.tooltipElement = this.renderer.createElement('span');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.tooltipText) // Set tooltip text
    );

    this.renderer.appendChild(document.body, this.tooltipElement);
    this.renderer.setStyle(this.tooltipElement, 'position', 'fixed');
    this.renderer.setStyle(this.tooltipElement, 'backgroundColor', '#000');
    this.renderer.setStyle(this.tooltipElement, 'color', '#f7f3f2');
    this.renderer.setStyle(this.tooltipElement, 'padding', '5px 7px');
    this.renderer.setStyle(this.tooltipElement, 'borderRadius', '5px');
    this.renderer.setStyle(this.tooltipElement, 'zIndex', '10000');
    this.renderer.setStyle(this.tooltipElement, 'pointerEvents', 'none');
    this.renderer.setStyle(this.tooltipElement, 'font-family', 'Verdana, sans-serif');
    this.renderer.setStyle(this.tooltipElement, 'font-size', '12px');
  }

  hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  ngOnDestroy() {
    this.hideTooltip(); // Ensure tooltip is hidden on directive destruction
    this.routerSubscription.unsubscribe(); // Cleanup router subscription
  }
}
