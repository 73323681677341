export class AddCareTeam {
  static readonly type = '[CareTeam] Add';
  constructor(public payload) {}
}

export class UpdateCareTeam {
  static readonly type = '[CareTeam] Update';
  constructor(public payload) {}
}

// export class FetchCareTeam {
//   static readonly type = '[CareTeam] Fetch One';
//   constructor(public id: string) {}
// }

export class FetchAllCareTeams {
  static readonly type = '[CareTeam] Fetch All';
}

export class DeleteCareTeam {
  static readonly type = '[CareTeam] Delete';
  constructor(public id: string) {}
}

export class ClearCareteamResponse {
  static readonly type = '[CareTeam] Clear Response';
}

export class ClearCareteams {
  static readonly type = '[CareTeam] Clear List';
}
