import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import {
  BaseComponent,
  ClearImageData,
  CommonState,
  DownloadFile,
} from '@quipo/quipo-lib';
import { Base64Service } from 'libs/quipo-lib/src/core/base/base64.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'quipo-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss'],
})
export class DownloadFileComponent extends BaseComponent {
  @Select(CommonState.getEncodedData) encodedData$: Observable<any>;
  encodedData;
  private b64Service: Base64Service;

  constructor(store: Store, private sanitizer: DomSanitizer) {
    super(store);
    const url = location.pathname.replace('/download', 'get-file');
    store.dispatch(new ClearImageData());
    this.store.dispatch(new DownloadFile(url));
    this.b64Service = new Base64Service(this.domsanitizer);
    this.encodedData$.pipe(takeUntil(this.destroy$)).subscribe((path) => {
      if (path && path.image) {
        this.encodedData = path.image;
        this.getdocuments();
      }
    });
  }

  getdocuments() {
    const that = this;
    const fileType = this.getFileExtension(that.encodedData.fileName);
    const newstr = this.replaceString(fileType, that.encodedData.base64Content);
    let filename = that.encodedData.fileName.split('_');
    filename = filename[1] ? filename[1] : that.encodedData.fileName;
    this.b64Service.openFile(newstr, fileType, filename);
  }
}
