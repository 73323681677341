import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';

import { LocalDatePipe } from './pipes/localDatePipe';
import { OrderByPipe } from './pipes/orderByPipe';
import { FilterPipe } from './pipes/filterPipe';
// import ngx-translate and the http loader
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { OrderByNameDatePipe } from './pipes/orderByNameAndDate.pipe';

// required for AOT compilation for ngx-translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [LocalDatePipe, OrderByPipe, FilterPipe, OrderByNameDatePipe],
  providers: [{ provide: LOCALE_ID, useValue: window.navigator.language }],
  exports: [
    TranslateModule,
    LocalDatePipe,
    OrderByPipe,
    FilterPipe,
    OrderByNameDatePipe,
  ],
})
export class InternationalizationModule {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en'
    );
  }
}
