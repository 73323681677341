export const Permissions = {
  checkIn: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,

        routes: [
          {
            routeName: 'check-in',
            fullPermission: true,
          },
        ],
      },
      {
        routeName: 'patient',
        fullPermission: false,
        routes: [
          {
            routeName: 'questionnaire',
            fullPermission: true,
          },
        ],
      },
    ],
  },
  patient: {
    routes: [
      {
        routeName: 'patient',
        fullPermission: true,
      },
    ],
  },
  superAdmin: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: true,
      },
    ],
  },
  doctor: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,
        routes: [
          {
            routeName: 'resources',
            fullPermission: false,
            restricted: ['edit', 'remove'],
          },
          { routeName: 'reports', fullPermission: true },
          { routeName: 'waiting-list', fullPermission: true },
          { routeName: 'patients', fullPermission: true },
          { routeName: 'patient-auth', fullPermission: true },
          {
            routeName: 'timer-reports',
            fullPermission: true,
          },
          {
            routeName: 'settings',
            fullPermission: false,
            tabs: [
              {
                name: 'general',
                restricted: ['add', 'edit', 'upload logo'],
              },
              {
                name: 'locations',
                restricted: [
                  'add',
                  'edit',
                  'review prompt',
                  'add review',
                  'edit review',
                ],
              },
              {
                name: 'doctors',
                restricted: ['add', 'edit'],
              },
              {
                name: 'staff',
                restricted: ['add', 'edit', 'select locations'],
              },
              {
                name: 'clinicPolicy',
                restricted: ['add', 'edit', 'preview', 'enable'],
              },
              {
                name: 'workPolicy',
                restricted: ['add', 'edit', 'preview', 'enable'],
              },
            ],
          },
          {
            routeName: 'physician',
            fullPermission: false,
            routes: [
              { routeName: 'consultation', fullPermission: true },
              { routeName: 'availability', fullPermission: true },
              { routeName: 'my-profile', fullPermission: true },
              {
                routeName: 'questionnaire',
                fullPermission: false,
                restricted: ['add'],
              },
            ],
          },
          { routeName: 'appointment-video-call', fullPermission: true },
        ],
      },
    ],
  },
  pharmacist: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,
        routes: [
          {
            routeName: 'physician',
            fullPermission: false,
            routes: [
              {
                routeName: 'consultation',
                fullPermission: false,
                routes: [
                  {
                    routeName: 'virtual-care',
                    fullPermission: false,
                    restricted: [
                      'start',
                      'cancel',
                      'call patient',
                      'To-Do',
                      'view',
                    ],
                  },
                ],
              },
              {
                routeName: 'my-profile',
                fullPermission: false,
                restricted: ['add signature'],
              },
            ],
          },
          {
            routeName: 'timer-reports',
            fullPermission: true,
          },
        ],
      },
    ],
  },
  medicalOfficeAssistant: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,
        routes: [
          { routeName: 'resources', fullPermission: true },
          { routeName: 'reports', fullPermission: true },
          { routeName: 'patients', fullPermission: true },
          { routeName: 'settings', fullPermission: true },
          {
            routeName: 'timer-reports',
            fullPermission: false,
            restricted: ['edit'],
          },
          {
            routeName: 'physician',
            fullPermission: false,
            routes: [
              {
                routeName: 'consultation',
                fullPermission: false,
                routes: [
                  {
                    routeName: 'virtual-care',
                    fullPermission: false,
                    restricted: ['start'],
                  },
                ],
              },
              {
                routeName: 'questionnaire',
                fullPermission: false,
                restricted: ['enable', 'add'],
              },
              { routeName: 'availability', fullPermission: true },
              {
                routeName: 'my-profile',
                fullPermission: false,
                restricted: ['add signature'],
              },
            ],
          },
          { routeName: 'appointment-video-call', fullPermission: true },
          { routeName: 'waiting-list', fullPermission: true },
          { routeName: 'patient-auth', fullPermission: true },
        ],
      },
    ],
  },
  alliedPractitioner: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,
        routes: [
          {
            routeName: 'resources',
            fullPermission: false,
            restricted: ['edit', 'remove'],
          },
          { routeName: 'reports', fullPermission: true },
          { routeName: 'patients', fullPermission: true },
          { routeName: 'waiting-list', fullPermission: true },
          { routeName: 'timer-reports', fullPermission: true },
          {
            routeName: 'settings',
            fullPermission: false,
            tabs: [
              {
                name: 'general',
                restricted: ['add', 'edit', 'upload logo'],
              },
              {
                name: 'locations',
                restricted: [
                  'add',
                  'edit',
                  'review prompt',
                  'add review',
                  'edit review',
                ],
              },
              {
                name: 'doctors',
                restricted: ['add', 'edit'],
              },
              {
                name: 'staff',
                restricted: ['add', 'edit', 'select locations'],
              },
              {
                name: 'clinicPolicy',
                restricted: ['add', 'edit', 'preview', 'enable'],
              },
              {
                name: 'workPolicy',
                restricted: ['add', 'edit', 'preview', 'enable'],
              },
            ],
          },
          {
            routeName: 'physician',
            fullPermission: false,
            routes: [
              { routeName: 'consultation', fullPermission: true },
              { routeName: 'availability', fullPermission: true },
              { routeName: 'my-profile', fullPermission: true },
              {
                routeName: 'questionnaire',
                fullPermission: false,
                restricted: ['add'],
              },
            ],
          },
          { routeName: 'appointment-video-call', fullPermission: true },
        ],
      },
    ],
    // routes: [
    //   {
    //     routeName: 'clinic',
    //     fullPermission: false,
    //     routes: [
    //       {
    //         routeName: 'resources',
    //         fullPermission: false,
    //         restricted: ['add', 'edit', 'remove'],
    //       },
    //       { routeName: 'reports', fullPermission: true },
    //       { routeName: 'patients', fullPermission: true },
    //       {
    //         routeName: 'settings',
    //         fullPermission: false,
    //         tabs: [
    //           {
    //             name: 'general',
    //             restricted: ['add', 'edit', 'upload logo'],
    //           },
    //           {
    //             name: 'locations',
    //             restricted: [
    //               'add',
    //               'edit',
    //               'review prompt',
    //               'add review',
    //               'edit review',
    //             ],
    //           },
    //           {
    //             name: 'doctors',
    //             restricted: ['add', 'edit'],
    //           },
    //           {
    //             name: 'staff',
    //             restricted: ['add', 'edit', 'select locations'],
    //           },
    //           {
    //             name: 'clinicPolicy',
    //             restricted: ['add', 'edit', 'preview', 'enable'],
    //           },
    //           {
    //             name: 'workPolicy',
    //             restricted: ['add', 'edit', 'preview', 'enable'],
    //           },
    //         ],
    //       },
    //       {
    //         routeName: 'physician',
    //         fullPermission: false,
    //         routes: [
    //           { routeName: 'consultation', fullPermission: true },
    //           {
    //             routeName: 'questionnaire',
    //             fullPermission: false,
    //             restricted: ['add', 'edit', 'enable'],
    //           },
    //           { routeName: 'availability', fullPermission: true },
    //           { routeName: 'my-profile', fullPermission: true },
    //         ],
    //       },
    //       { routeName: 'appointment-video-call', fullPermission: true },
    //     ],
    //   },
    // ],
  },
  administrator: {
    routes: [
      {
        routeName: 'clinic',
        fullPermission: false,
        routes: [
          { routeName: 'reports', fullPermission: true },
          { routeName: 'patients', fullPermission: true },
          { routeName: 'waiting-list', fullPermission: true },
          { routeName: 'timer-reports', fullPermission: true },

          {
            routeName: 'settings',
            fullPermission: false,
            tabs: [
              {
                name: 'general',
                restricted: ['add', 'edit', 'upload logo'],
              },
            ],
          },
          {
            routeName: 'physician',
            fullPermission: false,
            routes: [
              {
                routeName: 'consultation',
                fullPermission: false,
                routes: [
                  {
                    routeName: 'virtual-care',
                    fullPermission: false,
                    restricted: ['start', 'call patient', 'view'],
                  },
                ],
              },
              {
                routeName: 'availability',
                fullPermission: true,
              },
              {
                routeName: 'my-profile',
                fullPermission: false,
                restricted: ['add signature'],
              },
              {
                routeName: 'questionnaire',
                fullPermission: false,
                restricted: ['add', 'enable'],
              },
            ],
          },
          { routeName: 'appointment-video-call', fullPermission: true },
          { routeName: 'delete-requests', fullPermission: true },
          {
            routeName: 'pending-invoices',
            fullPermission: true,
          },
        ],
      },
    ],
  },
};
