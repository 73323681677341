import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { mapboxComponent } from './mapbox.component';
import { CoreModule } from '../../../core/core.module';
import { AutoCompleteModule } from '../autocomplete/autocomplete.module';

@NgModule({
  declarations: [mapboxComponent],
  imports: [CommonModule, FormsModule, CoreModule, AutoCompleteModule],
  exports: [mapboxComponent],
})
export class mapboxModule {}
