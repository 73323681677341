export class AddFamilyMember {
  static readonly type = '[FamilyMember] Add';
  constructor(public payload) {}
}

export class UpdateFamilyMember {
  static readonly type = '[FamilyMember] Update';
  constructor(public payload) {}
}
export class DeleteFamilyMember {
  static readonly type = '[FamilyMember] Delete';
  constructor(public payload) {}
}

// export class FetchFamilyMember {
//   static readonly type = '[FamilyMember] Fetch One';
//   constructor(public id: string) {}
// }

export class FetchAllFamilyMembers {
  static readonly type = '[FamilyMember] Fetch All';
}

export class ClearFamilyMemberResponse {
  static readonly type = '[FamilyMember] Clear Response';
}
export class SetSelectedPatient {
  static readonly type = '[FamilyMember] Set';
  constructor(public payload) {}
}
export class ClearFamilyMembers {
  static readonly type = '[FamilyMember] Clear All';
}
export class UpdatePharmacy {
  static readonly type = '[Pharmacy] Update';
  constructor(public payload) {}
}
