export class SetGender {
  static readonly type = '[Gender] SetGender';
  constructor(public payload: string) {}
}
export class SetLanguage {
  static readonly type = '[Language] SetLanguage';
  constructor(public payload: string) {}
}
export class FetchData {
  static readonly type = '[LookUpData] FetchData';
  constructor(public payload: string) {}
}
export class FetchPharmacyData {
  static readonly type = '[PharmacyListData] FetchPharmacyData';
  constructor(public payload: string) {}
}
export class InitOscarIntegration {
  static readonly type = '[Oscar] Init';
}
export class authorizeOscarIntegration {
  static readonly type = '[Oscar] Authorize Init';
  constructor(public oauthVerifier: string, public oauthToken: string) {}
}
export class clearOscarAuthorizeFlag {
  static readonly type = '[Oscar] clear Oscar Init & Authorize details';
  constructor() {}
}
export class FetchDoctorsList {
  static readonly type = '[DoctorsList] FetchData';
}
export class FetchStripeReaders {
  static readonly type = '[StripeReaders] FetchData';
  constructor(public payload) {}
}

export class ClearFiles {
  static readonly type = '[FileUpload] Clear';
  constructor() {}
}
export class FetchAllergyLookup {
  static readonly type = '[AllergyLookup] FetchAllergyLookup';
  constructor(public payload: string) {}
}
export class FetchConditionLookup {
  static readonly type = '[ConditonLookup] FetchConditonLookup';
  constructor(public payload: string) {}
}
export class FetchImmunizationLookup {
  static readonly type = '[ImmunizationLookup] FetchImmunizationLookup';
  constructor(public payload: string) {}
}
export class FetchProcedureLookup {
  static readonly type = '[ProcedureLookup] FetchProcedureLookup';
  constructor(public payload: string) {}
}
export class SetRestrictedActions {
  static readonly type = '[Actions] Restricted';
  constructor(public payload: string) {}
}
export class FetchLocation {
  static readonly type = '[Location] Fetch';
  constructor(public payload: string) {}
}
export class ClearLocation {
  static readonly type = '[Location] Clear';
}

export class PostFile {
  static readonly type = '[File] Post';
  constructor(public payload: any) {}
}
export class ClearDocsPath {
  static readonly type = '[File] Clear';
}
export class ClearImageData {
  static readonly type = '[Image File] Clear';
}
export class FetchFile {
  static readonly type = '[File] Fetch';
  constructor(public payload: any) {}
}

export class FetchDownloadable {
  static readonly type = '[File] Download';
  constructor(public payload: any) {}
}
export class DownloadFile {
  static readonly type = 'Download File';
  constructor(public payload: any) {}
}

export class SetAppLanguage {
  static readonly type = '[App Language] Set';
  constructor(public payload: any) {}
}
export class UpdateKeycloakUser {
  static readonly type = '[Keycloak] Update';
  constructor(public payload: any) {}
}
export class ClearKeycloakStatus {
  static readonly type = '[Keycloak Status] Clear';
}

export class FetchTutorials {
  static readonly type = '[Tutorials] Fetch';
  constructor(public payload: any) {}
}
export class DownloadTutorial {
  static readonly type = '[Tutorials] Download';
  constructor(public payload: any) {}
}
