import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  fetchPatientDetailsUsingOscarId(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    let url = `${environment.serviceIntegrationUrl}/search-oscar-patient-with-oscarid`;
    return this.http.post(
      url,
      {
        demographicNo: payload.demographicNo
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  fetchPatientList(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    let url = `${environment.registrationUrl}/patients`;
    if (payload.searchIn && payload.searchIn == 'Oscar')
        url = `${environment.serviceIntegrationUrl}/search-oscar-patients`;
    return this.http.post(
      url,
      {
        searchConditions: payload.text,
        searchType: payload.type,
        searchField: payload.field,
        searchIn:payload.searchIn
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }
  fetchPatientInfo(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/patients/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
 fetchPatientInfoByOscarId(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/patient-by-oscarid/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  
  FetchPrevAppointmentList(payload, accessToken): Observable<any> {
    const now: any = moment();
    const obj = {
      date: now.format('YYYY-MM-DD'),
      hour: now.format('HH'),
      minute: now.format('mm'),
    };
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/previous-appointments/${payload}`;
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  FetchQuestionnaireList(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.questionnaireUrl}/questionnaires/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchPatientMessage(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/message-list/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  updatePatientMessageStatus(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/message-list-mark-read/${payload}`;
    return this.http.patch(
      url,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }
  addPatientMessage(payload, id, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/message-list/${id}`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchPatientToDo(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/to-do-list/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  FetchPatientByOscarId(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/quipo-id/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  addPatientTodo(payload, id, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/to-do-list/${id}`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  updatePatientTodo(payload, id, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/to-do-list/${id}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  deleteTodo(payload, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/to-do-list/${payload.id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
      body: { id: payload.data },
    };
    return this.http.delete(url, httpOptions);
  }
  deletePatientMessage(payload, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/message-list/${payload.patientId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
      body: { id: payload.messageId },
    };
    return this.http.delete(url, httpOptions);
  }

  fetchPatientOnboardList(accessToken, payload): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/onboard-list`;
    return this.http.post(
      url,
      {
        searchConditions: payload.text,
        searchType: payload.type,
        searchField: payload.field,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
    // return this.http.get(url, {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //     Authorization: `Bearer ${accessToken}`,
    //     UserAuth: userToken,
    //   }),
    // });
  }
  registerOnBoardPatient(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/register-onboard-patient`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  authorizeOnBoardPatient(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/authorize-onboard-patient`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  blockOnBoardPatient(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/block-onboard-patient`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  sendPatientDeleteRequest(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/patient-delete-request/${payload.id}`;
    const obj = {
      isDelete: payload.isDelete,
      deleteReason: payload.deleteReason,
    };
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchPatientDeleteRequests(accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/delete-requests`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
