import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseComponent } from '../../../core//base';

@Component({
  selector: 'quipo-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent extends BaseComponent {
  @Input() submit: boolean;
  @Input() trueText: string;
  @Input() falseText: string;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter();

  constructor(store: Store) {
    super(store);
  }

  changeHandler() {
    this.valueChange.emit(this.value);
  }
}
