import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'quipo-phn-number',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {
  @Input() isRequired: boolean;
  @Input() submitted: boolean;
  @Input() isDisable: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  imageValue: string;
  inValid: boolean;

  change(newValue) {
  newValue = newValue.replace(/[a-zA-Z\s]+$/, '');
  newValue = newValue.replace(/(\d{4})(\d{3})(\d{3})/,'$1-$2-$3');
    this.valueChange.emit(newValue);
  }
}
