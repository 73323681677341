import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DownloadFileComponent } from './download-file/download-file.component';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('./features/patient/patient.module').then((m) => m.PatientModule),
  },

  {
    path: 'clinic',
    loadChildren: () =>
      import('./features/clinic/clinic.module').then((m) => m.ClinicModule),
  },
  {
    path: 'download/:id1/:id2/:id3',
    component: DownloadFileComponent,
  },
  {
    path: '**',
    redirectTo: 'pageNotFound',
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
