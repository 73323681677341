import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AddPatientToDo,
  ClearPatients,
  ClearTimerPatients,
  clearPatientDetailUsingOscarID,
  FetchPatientInfo,
  FetchPatientInfoByOscarId,
  fetchPatientDetailsUsingOscarId,
  FetchPatients,
  FetchTimerPatients,
  FetchPatientToDo,
  UserAuthState,
  FetchPrevAppointmentList,
  FetchQuestionnaireList,
  FetchPatientMessage,
  DeletePatientMessage,
  PatchPatientMessageStatus,
  AddPatientMessage,
  ClearPatientMessageResponse,
  FetchToken,
  FetchAllFamilyMembers,
  FetchPatientByOscarId,
  ClearPatientSelectionFromOscar,
  ClearPatientInfo, ClearPatientInfoByOscarId,
  ClearTODOResponse,
  DeletePatientToDo,
  FetchPatientsAuthList,
  RegisterOnBoardPatient,
  AuthorizeOnBoardPatient,
  BlockOnBoardPatient,
  UpdatePatientToDo,
  ClearOnboardBlockResponse,
  ClearOnboardRegisterResponse,
  ClearOnboardAuthorizeResponse,
  ClearPatientsAuthList,
  SendPatientDeleteRequest,
  FetchPatientDeleteRequests,
  ClearDeleteResponse,
} from '@quipo/quipo-lib';
import { tap } from 'rxjs/operators';

import { PatientsService } from '../services';
import { ClearDeleteRequests } from '../actions';

export class PatientsStateModel {
  patientsList: any;
  timerPatientsList: any;
  patientInfo: any;
  patientInfoByOscarId: any;
  questionnaireList: any;
  prevAppointmentList: any;
  todoList: any;
  todoResponse: any;
  patientMessage: any;
  patientMessageResponse: any;
  patientSelectionFromOscar: any;
  patientsAuthList: any;
  onboardAuthorizeResponse: any;
  onboardRegisterResponse: any;
  onboardBlockResponse: any;
  deleteRequests: any;
  deleteRequestResponse: any;
  foundPatientUsingOscarId: any;
}

@State<PatientsStateModel>({
  name: 'patients',
  defaults: {
    patientsList: undefined,
    timerPatientsList: undefined,
    patientInfo: undefined,
    patientInfoByOscarId: undefined,
    questionnaireList: undefined,
    prevAppointmentList: undefined,
    todoList: undefined,
    todoResponse: undefined,
    patientMessage: undefined,
    patientMessageResponse: undefined,
    patientSelectionFromOscar: undefined,
    patientsAuthList: undefined,
    onboardAuthorizeResponse: undefined,
    onboardRegisterResponse: undefined,
    onboardBlockResponse: undefined,
    deleteRequests: undefined,
    deleteRequestResponse: undefined,
    foundPatientUsingOscarId: undefined,
  },
})
@Injectable()
export class PatientsState {
  constructor(private store: Store, private patientsService: PatientsService) { }

  @Selector()
  static getTimerPatientsList(state: PatientsStateModel){
    return state.timerPatientsList;
  }

  @Selector()
  static getPatientsList(state: PatientsStateModel) {
    return state.patientsList;
  }

  @Selector()
  static getPatientDetailsUsingOscarID(state: PatientsStateModel) {
    return state.foundPatientUsingOscarId;
  }

  @Selector()
  static getQuestionnaireList(state: PatientsStateModel) {
    return state.questionnaireList;
  }

  @Selector()
  static getPrevAppointmentList(state: PatientsStateModel) {
    return state.prevAppointmentList;
  }

  @Selector()
  static getPatientInfo(state: PatientsStateModel) {
    return state.patientInfo;
  }
  @Selector()
  static getPatientInfoByOscarId(state: PatientsStateModel) {
    return state.patientInfoByOscarId;
  }
  @Selector()
  static getToDoList(state: PatientsStateModel) {
    return state.todoList;
  }
  @Selector()
  static getPatientSelectionFromOscar(state: PatientsStateModel) {
    return state.patientSelectionFromOscar;
  }
  @Selector()
  static getpatientMessageList(state: PatientsStateModel) {
    return state.patientMessage;
  }

  @Selector()
  static getpatientMessageResponse(state: PatientsStateModel) {
    return state.patientMessageResponse;
  }
  @Selector()
  static getToDoResponse(state: PatientsStateModel) {
    return state.todoResponse;
  }
  @Selector()
  static getOnboardAuthorizeResponse(state: PatientsStateModel) {
    return state.onboardAuthorizeResponse;
  }
  @Selector()
  static getOnboardRegisterResponse(state: PatientsStateModel) {
    return state.onboardRegisterResponse;
  }
  @Selector()
  static getoOboardBlockResponse(state: PatientsStateModel) {
    return state.onboardBlockResponse;
  }
  @Selector()
  static getPatientsAuthList(state: PatientsStateModel) {
    return state.patientsAuthList;
  }
  @Selector()
  static getPatientDeleteRequests(state: PatientsStateModel) {
    return state.deleteRequests;
  }
  @Selector()
  static getPatientDeleteRequestResponse(state: PatientsStateModel) {
    return state.deleteRequestResponse;
  }

  @Action(FetchPatients)
  fetchPatients(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService.fetchPatientList(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              patientsList: response.data,
            });
          } else {
            patchState({
              patientsList: undefined,
            });
          }
        }
      })
    );
  }

  @Action(fetchPatientDetailsUsingOscarId)
  fetchPatientDetailsUsingOscarId(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService.fetchPatientDetailsUsingOscarId(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          console.log(response);
          if (response.statusCode == 200) {
            patchState({
              foundPatientUsingOscarId: response.data,
            });
          } else {
            patchState({
              foundPatientUsingOscarId: undefined,
            });
          }
        }
      })
    );
  }

  @Action(FetchTimerPatients)
  fetchTimerPatients(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService.fetchPatientList(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              timerPatientsList: response.data,
            });
          } else {
            patchState({
              timerPatientsList: undefined,
            });
          }
        }
      })
    );
  }

  @Action(ClearTimerPatients)
  clearTimerPatients({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      timerPatientsList: undefined,
    });
  }

  @Action(clearPatientDetailUsingOscarID)
  clearPatientDetailUsingOscarID({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      foundPatientUsingOscarId: undefined,
    });
  }

  @Action(ClearPatients)
  clearPatients({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      patientsList: undefined,
    });
  }
  @Action(FetchPatientInfoByOscarId)
  FetchPatientInfoByOscarId(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService.fetchPatientInfoByOscarId(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            patchState({
              patientInfoByOscarId: response.data,
            });
          } else {
            patchState({
              patientInfoByOscarId: undefined,
            });
          }
        }
      })
    );
  }
  @Action(FetchPatientInfo)
  fetchPatientInfo(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService.fetchPatientInfo(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            patchState({
              patientInfo: response.data,
            });
          } else {
            patchState({
              patientInfo: undefined,
            });
          }
        }
      })
    );
  }

  @Action(ClearPatientInfo)
  clearPatientInfo({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      patientInfo: undefined,
    });
  }
  @Action(ClearPatientInfoByOscarId)
  ClearPatientInfoByOscarId({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      patientInfoByOscarId: undefined,
    });
  }
  @Action(FetchPatientToDo)
  fetchPatientToDo(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.patientsService.fetchPatientToDo(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              todoList: response.data ? response.data : [],
            });
          } else {
            patchState({
              todoList: undefined,
            });
          }
        } else {
          patchState({
            todoList: undefined,
          });
        }
      })
    );
  }
  @Action(AddPatientToDo)
  addPatientToDo(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .addPatientTodo(payload.data, payload.id, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              todoResponse: response,
            });
          } else {
            patchState({
              todoResponse: undefined,
            });
          }
        })
      );
  }
  @Action(FetchPatientMessage)
  FetchPatientMessage(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (payload) {
      return this.patientsService
        .fetchPatientMessage(payload, loginInfo.token)
        .pipe(
          tap((response) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                patchState({
                  patientMessage: response.data ? response.data : [],
                });
              } else {
                patchState({
                  patientMessage: undefined,
                });
              }
            } else {
              patchState({
                patientMessage: undefined,
              });
            }
          })
        );
    } else {
      patchState({
        patientMessage: undefined,
      });
    }
  }
  @Action(AddPatientMessage)
  AddPatientMessage(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .addPatientMessage(payload.data, payload.id, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              patientMessageResponse: response,
            });
          } else {
            patchState({
              patientMessageResponse: undefined,
            });
          }
        })
      );
  }

  @Action(UpdatePatientToDo)
  updatePatientToDo(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .updatePatientTodo(payload.data, payload.id, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              todoResponse: response,
            });
          } else {
            patchState({
              todoResponse: undefined,
            });
          }
        })
      );
  }
  @Action(FetchPrevAppointmentList)
  FetchPrevAppointmentList(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .FetchPrevAppointmentList(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200 && response.data) {
              patchState({
                prevAppointmentList: response.data,
              });
            } else {
              patchState({
                prevAppointmentList: undefined,
              });
            }
          }
        })
      );
  }

  @Action(FetchQuestionnaireList)
  FetchQuestionnaireList(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .FetchQuestionnaireList(payload.patientId, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200 && response.data) {
              if (
                payload.patientInfo?.gender &&
                payload.patientInfo.gender.itemCode == 'M'
              ) {
                response.data.forEach((item) => {
                  if (item.reasonId == 104) {
                    const question = item.questionnaire?.questionnare;
                    const compo = question.components[0].components;
                    if (compo) {
                      compo.forEach((element, index) => {
                        if (element.key == 'femaleSymptoms') {
                          compo.splice(index, 1);
                        }
                      });
                    }
                  }
                });
              }
              patchState({
                questionnaireList: response.data,
              });
            } else {
              patchState({
                questionnaireList: undefined,
              });
            }
          }
        })
      );
  }
  @Action(DeletePatientToDo)
  deleteTodo(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.patientsService.deleteTodo(payload, loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                this.store.dispatch(new FetchPatientToDo(payload.id));
              }
            }
          }
        })
      );
    }
  }

  @Action(ClearTODOResponse)
  clearResponse({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      todoResponse: undefined,
    });
  }
  @Action(ClearPatientMessageResponse)
  ClearPatientMessageResponse({
    patchState,
  }: StateContext<PatientsStateModel>) {
    patchState({
      patientMessageResponse: undefined,
    });
  }

  @Action(ClearOnboardAuthorizeResponse)
  ClearOnboardAuthorizeResponse({
    patchState,
  }: StateContext<PatientsStateModel>) {
    patchState({
      onboardAuthorizeResponse: undefined,
    });
  }
  @Action(ClearOnboardRegisterResponse)
  ClearOnboardRegisterResponse({
    patchState,
  }: StateContext<PatientsStateModel>) {
    patchState({
      onboardRegisterResponse: undefined,
    });
  }
  @Action(ClearPatientsAuthList)
  ClearPatientsAuthList({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      patientsAuthList: undefined,
    });
  }

  @Action(ClearOnboardBlockResponse)
  ClearOnboardBlockResponse({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      onboardBlockResponse: undefined,
    });
  }

  @Action(ClearPatientSelectionFromOscar)
  ClearPatientSelectionFromOscar({
    patchState,
  }: StateContext<PatientsStateModel>) {
    patchState({
      patientSelectionFromOscar: undefined,
    });
  }

  @Action(PatchPatientMessageStatus)
  PatchPatientMessageStatus(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (payload) {
      return this.patientsService
        .updatePatientMessageStatus(payload, loginInfo.token)
        .pipe(
          tap((response) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                this.store.dispatch(new FetchToken());
                this.store.dispatch(new FetchAllFamilyMembers());
              } else {
              }
            }
          })
        );
    } else {
      patchState({
        patientMessage: undefined,
      });
    }
  }

  @Action(DeletePatientMessage)
  deletePatientMessage(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.patientsService
        .deletePatientMessage(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  this.store.dispatch(
                    new FetchPatientMessage(payload.patientId)
                  );
                }
              }
            }
          })
        );
    }
  }

  @Action(FetchPatientByOscarId)
  FetchPatientByOscarId(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .FetchPatientByOscarId(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                patientSelectionFromOscar: response.data,
              });
            }
          }
        })
      );
  }

  @Action(FetchPatientsAuthList)
  fetchPatientsAuthList(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .fetchPatientOnboardList(loginInfo.token, payload)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                patientsAuthList: response.data,
              });
            }
          }
        })
      );
  }

  @Action(BlockOnBoardPatient)
  BlockOnBoardPatient(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .blockOnBoardPatient(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              onboardBlockResponse: response,
            });
          }
        })
      );
  }
  @Action(AuthorizeOnBoardPatient)
  AuthorizeOnBoardPatient(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .authorizeOnBoardPatient(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              onboardAuthorizeResponse: response,
            });
          }
        })
      );
  }
  @Action(RegisterOnBoardPatient)
  RegisterOnBoardPatient(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .registerOnBoardPatient(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              onboardRegisterResponse: response,
            });
          }
        })
      );
  }

  @Action(SendPatientDeleteRequest)
  postDeleteRequest(
    { patchState }: StateContext<PatientsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .sendPatientDeleteRequest(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              deleteRequestResponse: response,
            });
          } else {
            patchState({
              deleteRequestResponse: undefined,
            });
          }
        })
      );
  }

  @Action(FetchPatientDeleteRequests)
  fetchDeleteRequests({ patchState }: StateContext<PatientsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.patientsService
      .fetchPatientDeleteRequests(loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              deleteRequests: response.data,
            });
          }
        })
      );
  }

  @Action(ClearDeleteResponse)
  clearDeleteResponse({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      deleteRequestResponse: undefined,
    });
  }
  @Action(ClearDeleteRequests)
  clearDeleteRequests({ patchState }: StateContext<PatientsStateModel>) {
    patchState({
      deleteRequests: undefined,
    });
  }
}
