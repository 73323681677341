import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'quipo-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnChanges {
  @Input() value = '';
  @Input() filterType: string;
  @Input() itemList: any = [];
  @Input() itemName: any;
  @Input() isRequired = false;
  @Input() submitted = false;
  @Input() disabled: boolean;
  @Input() zIndex = '50';
  @Input() defaultFilter = false;
  @Input() showPharmAddr;
  @Input() pharmacyAddress;
  @Input() isLoading = false;

  @Output() valueChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();

  @Input() filteredItems: any = [];
  isInvalid = false;
  pharmAddress: any;

  ngOnChanges() {
    if (this.pharmacyAddress) {
      this.pharmAddress = this.value ? this.pharmacyAddress : '';
    }
    if (this.submitted) {
      const pharmDetObj = {
        preferedPharmacy: this.value,
        pharmacyAddress: this.pharmAddress,
      };
      if (this.showPharmAddr) this.selectionChange.emit(pharmDetObj);
    }
  }

  onSelect(item: any) {
    this.value = item;
    this.validate();
    if (this.showPharmAddr) {
      const splitPos = item.indexOf('-');
      const pharmDetObj = {
        preferedPharmacy: item.slice(0, splitPos).trim(),
        pharmacyAddress: item.slice(splitPos + 1).trim(),
      };
      this.value = pharmDetObj.preferedPharmacy;
      this.pharmAddress = pharmDetObj.pharmacyAddress;

      this.valueChange.emit(this.value);
      this.selectionChange.emit(pharmDetObj);
    } else {
      this.valueChange.emit(this.value);
      this.selectionChange.emit(this.value);
    }
    // this.valueChange.emit(this.value);
    // this.selectionChange.emit(this.value);
  }

  validate() {
    if (this.isRequired && !this.value) {
      this.isInvalid = true;
    } else {
      this.isInvalid = false;
    }
  }

  search(term: string) {
    term = term.trimStart();
    if (
      (!term && !this.defaultFilter) ||
      !this.itemList ||
      (this.itemList && this.itemList).length == 0
    ) {
      this.filteredItems = [];
      this.value = term;
    } else {
      this.filteredItems = this.itemList.filter((item) => {
        try {
          if (item) {
            switch (this.filterType) {
              case 'starts':
                return item.toLowerCase().startsWith(term.toLowerCase());
                break;
              case 'includes':
                return item.toLowerCase().includes(term.toLowerCase());
              case 'full':
                return item;
            }
          } else null;
        } catch (e) {
          console.log('Error loading lookup item', item);
        }
      });
      this.value = term;
    }
    this.validate();

    this.valueChange.emit(this.value);
    const pharmDetObj = {
      preferedPharmacy: this.value,
      pharmacyAddress: this.value ? this.pharmAddress : '',
    };
    this.selectionChange.emit(pharmDetObj);
  }

  pharAddrSearch(term: string) {
    if (
      (!term && !this.defaultFilter) ||
      !this.itemList ||
      (this.itemList && this.itemList).length == 0
    ) {
      this.filteredItems = [];
      this.pharmAddress = term;
    } else {
      this.filteredItems = this.itemList.filter((item) => {
        try {
          if (item) {
            return item.toLowerCase().includes(term.toLowerCase());
          } else null;
        } catch (e) {
          console.log('Error loading lookup item', item);
        }
      });
      this.pharmAddress = term;
    }
    const pharmDetObj = {
      preferedPharmacy: this.value,
      pharmacyAddress: this.value ? this.pharmAddress : '',
    };
    this.selectionChange.emit(pharmDetObj);
  }

  onPharmAddrSelect(item: any) {
    this.pharmAddress = item;
    this.validate();
    const splitPos = item.indexOf('-');
    const pharmDetObj = {
      preferedPharmacy: item.slice(0, splitPos).trim(),
      pharmacyAddress: item.slice(splitPos + 1).trim(),
    };
    this.value = pharmDetObj.preferedPharmacy;
    this.pharmAddress = pharmDetObj.pharmacyAddress;

    this.valueChange.emit(this.value);
    this.selectionChange.emit(pharmDetObj);
  }

  customFilter = function (lookupList: any[]): any[] {
    return lookupList;
  };
}
