export class FetchPhysicianProfile {
  static readonly type = '[PhysicianProfile] Fetch';
}

export class UpdatePhysicianProfile {
  static readonly type = '[PhysicianProfile] Update';
  constructor(public payload) {}
}

export class ClearPhysicianProfileResponse {
  static readonly type = '[PhysicianProfile] Clear Response';
}
export class ClearProfileData {
  static readonly type = '[PhysicianProfile] Clear data';
}
