import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailInputComponent } from './email-input.component';
import { CoreModule } from '../../../core/core.module';
@NgModule({
  declarations: [EmailInputComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CoreModule],
  exports: [EmailInputComponent],
})
export class EmailInputModule {}
