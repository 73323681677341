<div class="form-div form-icon">
  <img [src]="icon" *ngIf="icon" alt="loading.." />
  <input
    type="type"
    class="form-input"
    ngModel
    [ngModel]="value"
    #imageCode="ngModel"
    name="PHN"
    [placeholder]="placeholder"
    (ngModelChange)="change($event.trim())"
    quipoMandatory
    numbersOnly
    [rangeFrom]="10"
    [rangeTo]="12"
    maxlength="10"
    [submitted]="submitted"
    [disabled]="isDisable"
  />
  <label
    for="form-input"
    class="form-label"
    [ngClass]="{ 'form-label-placeholder': placeholder != ' ' }"
    >{{ label | translate }}
    <span *ngIf="isRequired" class="mandatory-field">*</span>
    </label
  >
</div>
<div *ngIf="submitted && imageCode.errors?.required">
  <strong class="form-validation-errors">{{
    'field-required' | translate
  }}</strong>
</div>
