<div class="form-div phone-input">
  <img
    class="form-icon"
    [ngClass]="{ 'icon-background': icon?.background }"
    [src]="icon?.path"
    *ngIf="icon?.isPresent"
  />
  <div class="phoneType" (click)="showList()" *ngIf="showSelection">
    <span class="selectedPhone">
      <img [src]="selectedType?.image" class="phone-img" />
      <span>{{ selectedType?.name }}</span>
    </span>
    <img src="assets/images/arrow-down.png" class="arrow-image phone-img" />
  </div>
  <div *ngIf="showImgList" class="type-list">
    <ul>
      <li
        *ngFor="let type of phoneTypeList"
        [value]="type.value"
        (click)="selectPhoneType(type)"
      >
        <img
          [src]="type.image"
          [ngStyle]="type.width && { width: type.width }"
        />
        <span>{{ type.name }}</span>
      </li>
    </ul>
  </div>
  <input
    class="form-input"
    type="tel"
    [placeholder]="placeholder"
    #phoneInput="ngModel"
    name="phoneInput"
    [ngModel]="value"
    (ngModelChange)="emitPhone($event)"
    (keypress)="keyPress($event)"
    [required]="required"
    maxlength="14"
  />
  <label
    for="form-input"
    class="form-label"
    [ngClass]="{ 'form-label-placeholder': placeholder != ' ' }"
    >{{ label | translate }}
    <span *ngIf="required" class = "mandatory-field">*</span>
    </label
  >
</div>
<div
  *ngIf="
    (phoneInput.invalid && (phoneInput.dirty || phoneInput.touched)) ||
    (submitted && phoneInput.errors?.required)
  "
  class="form-validation-errors"
>
  <strong>{{ 'field-required' | translate }}</strong>
</div>

<div
  *ngIf="
    (phoneInput.dirty || phoneInput.touched) &&
    !phoneInput.errors?.required &&
    phoneInput.value != '' &&
    !validPhone
  "
  class="form-validation-errors"
>
  <strong>{{ 'enter-valid' | translate }} {{ label }}</strong>
</div>
