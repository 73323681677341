import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[alphaNumericLimit]',
})
export class AlphaNumericLimitDirective {
  @Input() allowedLimit: any = 100;

  private check(value: string) {
    return (
      value.length <= this.allowedLimit &&
      String(value).match(new RegExp(/^[a-zA-Z0-9 ]*$/))
    );
  }

  private specialKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !this.check(next)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let clipboardValue = event.clipboardData.getData('text');
    if (!this.check(clipboardValue)) {
      event.preventDefault();
    }
  }
}
