export class FetchBillsByDate {
  static readonly type = '[Billing] Fetch By Date';
  constructor(public payload: any) {}
}

export class FetchChargeLookup {
  static readonly type = '[Billing] Fetch Lookup';
}

export class FetchClinicCharges {
  static readonly type = '[Billing] Fetch Charges';
}

export class FetchPatientList {
  static readonly type = '[Billing] Fetch Patient';
}

export class SetClinicCharges {
  static readonly type = '[Billing] Set';
  constructor(public payload: any) {}
}

export class AddPatientBill {
  static readonly type = '[Billing] Add';
  constructor(public payload: any) {}
}
export class DeletePatientBill {
  static readonly type = '[Billing] Delete';
  constructor(public payload: any) {}
}

export class ClearBillingResponse {
  static readonly type = '[Billing] Clear Response';
}

export class FetchBillsByDateFromOscar {
  static readonly type = '[Billing] Fetch By Date From Oscar';
  constructor(public payload: any) {}
}