import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  constructor(private http: HttpClient) { }
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  signUpUser(payload) {
    const url = `${environment.authUrl}/patient-sign-up`;
    return this.http.post(url, payload);
  }
  signUpKeyclockUser(payload) {
    const url = `${environment.authUrl}/patient-keycloack-sign-up`;
    return this.http.post(url, payload);
  }
  getKeycloakUserDetails(keycloakUserId) {
    const url = `${environment.authUrl}/keycloack-user/${keycloakUserId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }
  loginUser(payload) {
    const url = `${environment.authUrl}/sigin-in`;
    return this.http.post(url, payload);
  }
  resendVerificationMail(payload) {
    const url = `${environment.authUrl}/verification-mail`;
    return this.http.post(url, payload);
  }
  forgotpasswordMail(payload) {
    const url = `${environment.authUrl}/forgotpassword-mail`;
    return this.http.post(url, payload);
  }
  // Servie call for fetching billing invoice list
  fetchBillingInvoiceList(accessToken, oscarPatientId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/patient-invoice/${oscarPatientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Servie call for get payment response
  getBillingPaymentResponse(accessToken, payload: any) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/pay-invoice/${payload.invoiceId}`;
    return this.http.post(
      url,
      {
        invoiceAmount: payload.invoiceAmount,
        selectedCharges: payload.selectedCharges,
        stripeId: payload.stripeId,
        address: payload.address,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }
  initiateOscarCall(payload) {
    const url = `${environment.authUrl}/validate-oscar-consultation`;
    return this.http.post(url, payload);
  }

  getToken(accessToken) {
    const now = moment();
    const today = now.format('YYYY-MM-DD');
    const dateTime = now.format('MM/DD/YYYY HH:mm:ss');
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/refresh-token`;
    return this.http.post(
      url,
      { today: today, dateTime: dateTime },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  getUserInfo(accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/user-info`;
    return this.http.post(
      url,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }
  payInvoiceByCash(accessToken, invoiceId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/pay-invoice/${invoiceId}`;
    return this.http.post(
      url,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  payInvoiceByStripeCard(accessToken, payload) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/stripe/pay-invoice-using-reader/${payload.invoiceId}`;
    return this.http.post(url, payload.data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  confirmPatientCheckIn(accessToken, payload) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/check-in-details`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  patientCheckIn(accessToken, payload) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/patient-check-in`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  validatePHN(accessToken, payload): Observable<any> {
    const url = `${environment.authUrl}/check-phn`;
    return this.http.post(url, { phn: payload });
  }

  // Service call for fetching all pending invoices in an account
  fetchPendingInvoiceList(accessToken, patientId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/patient-pending-invoices/${patientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  getProviderToken(accessToken, doctorId) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/get-user-token/${doctorId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  resetPassword(payload) {
    const now = moment();
    // const currentDateTime = now.format('MM/DD/YYYY HH:mm:ss');
    const newPayload = { ...payload, timeofpasswordreset: now }
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/reset-password`;
    return this.http.post(url, newPayload);
  }
}
