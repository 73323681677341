export class FetchAppointments {
  static readonly type = '[Appointments] Fetch';
  constructor(public payload: any) {}
}
export class FetchTimerAppointments{
  static readonly type = '[Timer Appointments] Fetch';
  constructor(public payload: any) {}
}

export class FetchAppointmentDetail {
  static readonly type = '[Appointments Detail] Fetch';
  constructor(public payload: string) {}
}
export class FetchOscarAppointmentDetailByPatientId {
  static readonly type = '[Oscar Appointments Detail] Fetch';
  constructor(public payload: string) {}
}

export class FetchHealthSummaryDetails {
  static readonly type = '[Summary Detail] Fetch';
  constructor(public payload: string) {}
}

export class SetPatientInfo {
  static readonly type = '[Appointment] SetPatient';
  constructor(public payload: any) {}
}
export class ClearAppointments {
  static readonly type = '[Appointment] Clear';
}
export class ClearEmailResponse {
  static readonly type = '[Email Response] Clear';
}
export class CancelAppointment {
  static readonly type = '[Appointment] Cancel';
  constructor(public payload: any) {}
}
export class UpdateAppointmentStatus {
  static readonly type = '[Appointment] Update Appointment Status';
  constructor(public payload: any) {}
}

export class InitTwilio {
  static readonly type = '[TwilioToken] Fetch';
  constructor() {}
}
export class SendEmail {
  static readonly type = '[Email] Fetch';
  constructor(public payload: any) {}
}
export class SendSMS {
  static readonly type = '[SMS] Fetch';
  constructor(public payload: any) {}
}

export class ClearSMSResponse {
  static readonly type = '[SMS Response] Clear';
}

export class ClearTwilioToken {
  static readonly type = '[Twilio Token Response] Clear';
}
export class SendEncounder {
  static readonly type = '[Encounder] Fetch';
  constructor(public payload: any) {}
}
export class ClearEncounderResponse {
  static readonly type = '[Encounder Response] Clear';
}

export class SetVideoCallStatus {
  static readonly type = '[VideoCall Status] Set';
  constructor(public payload: any) {}
}
