import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  FetchAllCareTeams,
  // FetchCareTeam,
  AddCareTeam,
  UpdateCareTeam,
  DeleteCareTeam,
  ClearCareteamResponse,
  ClearCareteams,
} from '../actions/care-team.action';
import { CareTeamService } from '../services/care-team.service';
import {
  CareteamDetails,
  CareteamResponse,
} from '../interfaces/care-team.interface';
import { Store } from '@ngxs/store';
import { UserAuthState } from './user-auth.state';
import { FamilyMemberState } from './family-member.state';

export class CareTeamStateModel {
  careteams: CareteamDetails[];
  careteamResponse: CareteamResponse;
}
@State<CareTeamStateModel>({
  name: 'careTeams',
  defaults: {
    careteams: [],
    careteamResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
  },
})
@Injectable()
export class CareTeamState {
  constructor(private careTeamService: CareTeamService, private store: Store) {}

  @Selector()
  static getCareTeamDetails(state: CareTeamStateModel) {
    return state.careteams;
  }
  @Selector()
  static getCareteamResponse(state: CareTeamStateModel) {
    return state.careteamResponse;
  }

  @Action(ClearCareteamResponse)
  ClearCareteamResponse({ patchState }: StateContext<CareTeamStateModel>) {
    patchState({
      careteamResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }

  @Action(ClearCareteams)
  ClearCareteamsList({ patchState }: StateContext<CareTeamStateModel>) {
    patchState({
      careteams: undefined,
    });
  }

  @Action(FetchAllCareTeams)
  FetchAllCareTeams({ patchState }: StateContext<CareTeamStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selectedPatient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token && selectedPatient && selectedPatient.id) {
      return this.careTeamService
        .getcareTeams(loginInfo.token, selectedPatient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    careteams: response.data,
                  });
                  return;
                }
              }
              patchState({
                careteams: [],
              });
            }
          })
        );
    }
  }

  @Action(AddCareTeam)
  AddCareTeam(
    { patchState }: StateContext<CareTeamStateModel>,
    { payload }: AddCareTeam
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selectedPatient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    if (loginInfo && loginInfo.token && selectedPatient && selectedPatient.id) {
      return this.careTeamService
        .postCareTeam(payload, loginInfo.token, selectedPatient.id)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    careteamResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  careteamResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                careteamResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }
  @Action(UpdateCareTeam)
  UpdateCareTeam(
    { patchState }: StateContext<CareTeamStateModel>,
    { payload }: UpdateCareTeam
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.careTeamService.patchCareTeam(payload, loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  careteamResponse: {
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
              }
            } else {
              patchState({
                careteamResponse: {
                  statusCode: response.statusCode,
                  errorMessage: response.message ? response.message : 'Error',
                },
              });
            }
          } else {
            patchState({
              careteamResponse: {
                statusCode: 0,
                errorMessage: undefined,
              },
            });
          }
        })
      );
    }
  }

  @Action(DeleteCareTeam)
  DeleteCareTeam(
    { patchState }: StateContext<CareTeamStateModel>,
    { id }: DeleteCareTeam
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.careTeamService.deleteCareTeam(id, loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                this.store.dispatch(new FetchAllCareTeams());
              }
            }
          }
        })
      );
    }
  }
}
