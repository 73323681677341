export class ClearHealthSummaryResponse {
  static readonly type = '[HealthSummary] Clear All';
}

// Health Summary - CONDITIONS
export class FetchHealthSummaryConditions {
  static readonly type = '[HealthSummary] Fetch All Conditions';
}

export class FetchHealthSummaryCondition {
  static readonly type = '[HealthSummary] Fetch Particular Conditions';
  constructor(public conditionId) {}
}

export class ClearHealthSummaryConditions {
  static readonly type = '[HealthSummary] Clear All Conditions';
}

export class FetchCareTeamMembers {
  static readonly type = '[HealthSummary] Fetch All Care Teams';
}

export class SaveHealthSummaryCondition {
  static readonly type = '[HealthSummary] Save Condition';
  constructor(public payload) {}
}

export class PatchHealthSummaryCondition {
  static readonly type = '[HealthSummary] Update Condition';
  constructor(public payload, public conditionId) {}
}

// Health Summary - PROCEDURES
export class FetchHealthSummaryProcedures {
  static readonly type = '[HealthSummary] Fetch All Procedures';
}

export class FetchHealthSummaryProcedure {
  static readonly type = '[HealthSummary] Fetch Particular Procedure';
  constructor(public procedureId) {}
}

export class SaveHealthSummaryProcedure {
  static readonly type = '[HealthSummary] Save Procedure';
  constructor(public payload) {}
}
export class PatchHealthSummaryProcedure {
  static readonly type = '[HealthSummary] Update Procedure';
  constructor(public payload, public procedureId) {}
}

// Health Summary - ALLERGIES
export class FetchHealthSummaryAllergies {
  static readonly type = '[HealthSummary] Fetch All Allergies';
}

export class FetchHealthSummaryAllergy {
  static readonly type = '[HealthSummary] Fetch Particular Allergy';
  constructor(public allergyId) {}
}

export class SaveHealthSummaryAllergy {
  static readonly type = '[HealthSummary] Save Allergy';
  constructor(public payload) {}
}
export class PatchHealthSummaryAllergy {
  static readonly type = '[HealthSummary] Update Allergy';
  constructor(public payload, public allergyId) {}
}

// Health Summary - MEDICATIONS
export class FetchHealthSummaryMedications {
  static readonly type = '[HealthSummary] Fetch All Medications';
}

export class FetchHealthSummaryMedication {
  static readonly type = '[HealthSummary] Fetch Particular Medication';
  constructor(public medicationId) {}
}

export class SaveHealthSummaryMedication {
  static readonly type = '[HealthSummary] Save Medication';
  constructor(public payload) {}
}
export class PatchHealthSummaryMedication {
  static readonly type = '[HealthSummary] Update Medication';
  constructor(public payload, public medicationId) {}
}

export class FetchHealthSummaryMedicineLookup {
  static readonly type = '[HealthSummary] Fetch All Medicines';
}

// Health Summary - IMMUNIZATIONS
export class FetchHealthSummaryImmunizations {
  static readonly type = '[HealthSummary] Fetch All Immunizations';
}

export class FetchHealthSummaryImmunization {
  static readonly type = '[HealthSummary] Fetch Particular Immunization';
  constructor(public immunizationId) {}
}

export class SaveHealthSummaryImmunization {
  static readonly type = '[HealthSummary] Save Immunizations';
  constructor(public payload) {}
}
export class PatchHealthSummaryImmunization {
  static readonly type = '[HealthSummary] Update Immunizations';
  constructor(public payload, public immunizationId) {}
}

// Health Summary - HEALTH-DOCUMENTS
export class FetchHealthSummaryHealthDocuments {
  static readonly type = '[HealthSummary] Fetch All HealthDocuments';
}

export class FetchHealthSummaryHealthDocument {
  static readonly type = '[HealthSummary] Fetch Particular HealthDocument';
  constructor(public documentId) {}
}

export class SaveHealthSummaryHealthDocument {
  static readonly type = '[HealthSummary] Save HealthDocument';
  constructor(public payload) {}
}
export class PatchHealthSummaryHealthDocument {
  static readonly type = '[HealthSummary] Update HealthDocument';
  constructor(public payload, public documentId) {}
}

export class FetchHealthSummaryLookupData {
  static readonly type = '[HealthSummary] Fetch Lookup';
  constructor(public lookupItem) {}
}
export class ClearHealthSummaryLookupData {
  static readonly type = '[HealthSummary] Clear Lookup';
}

export class ClearHealthSummary {
  static readonly type = '[HealthSummary] Clear All';
}

export class ClearShareHealthSummaryDetails {
  static readonly type = '[Share Health Summary] Clear';
}

export class ClearHealthDocumentData {
  static readonly type = '[HealthSummary] Clear HealthDocument';
}

export class FetchRecentHealthSummary {
  static readonly type = '[HealthSummary] Fetch Recent Health Summary';
}

// Clear
export class ClearHealthSummaryProcedures {
  static readonly type = '[HealthSummary] Clear All Procedures';
}
export class ClearHealthSummaryAllergies {
  static readonly type = '[HealthSummary] Clear All Allergies';
}
export class ClearHealthSummaryImmunizations {
  static readonly type = '[HealthSummary] Clear All Immunizations';
}
export class ClearHealthSummaryMedications {
  static readonly type = '[HealthSummary] Clear All Medications';
}
export class ClearHealthSummaryHealthDocuments {
  static readonly type = '[HealthSummary] Clear All Health Documents';
}

export class FetchHealthSummaryForms {
  static readonly type = '[HealthSummary] Forms Fetch';
}

export class ClearHealthSummaryForms {
  static readonly type = '[HealthSummary] Forms Clear ';
}
