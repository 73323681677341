import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { IDatePickerConfig } from 'ng2-date-picker';
import {
  IAngularMyDpOptions,
  IMyDateModel,
  DefaultView,
  CalAnimation,
  ActiveView,
  AngularMyDatePickerDirective,
  HeaderAction,
} from 'angular-mydatepicker';
import { Router } from '@angular/router';

@Component({
  selector: 'quipo-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent
  implements OnInit, OnChanges, AfterContentChecked {
  @Input() isMandatory: boolean;
  @Input() hideError: boolean = false;
  @Input() submitted: boolean;
  @Input() isDisable: boolean;
  @Input() mode: string;
  @Input() isRequired: boolean;
  @Input() label: string;
  @Input() icon: any;
  @Input() config;
  @Input() value: string;
  @Input() disableDateList: any = [];
  @Input() highlightDateList: any = [];
  @Input() datepickerWidth = '16.625em';
  @Input() datepickerHeight = '16.625em';
  //manually trigger onchanges
  @Input() manualTrigger = false
  @Output() valueChange = new EventEmitter();
  @Output() dateChangeEvent = new EventEmitter();
  @Output() monthSelectionChangeEvent = new EventEmitter();
  isInvalid: boolean;
  public datePickerConfig = <IDatePickerConfig>{};
  placeholder: string;
  selectedDate: string;
  prevValue: string;
  dateinputdata = '';
  crossflag = false;

  crossicon = {
    path: 'assets/images/blue-cross-icon.svg',
  };

  myDpOptions: IAngularMyDpOptions;

  model: IMyDateModel = null;
  selectedMonth = 0;
  selectedYear = 0;
  prevMonth = 0;
  prevYear = 0;
  viewChanged = false;
  @ViewChild('dp') mydp: AngularMyDatePickerDirective;

  isEdit = false;

  constructor(router: Router) {
    if (router.url.includes('appointments/edit')) this.isEdit = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.myDpOptions = {
      dateFormat: this.config?.format ? this.config.format : 'yyyy-mm-dd',
      disableUntil: this.config?.disableUntil
        ? this.config.disableUntil
        : { year: 0, month: 0, day: 0 },
      disableSince: this.config?.disableSince
        ? this.config.disableSince
        : { year: 0, month: 0, day: 0 },
      disableDates: this.disableDateList,
      markDates: [
        {
          dates: this.highlightDateList,
          styleClass: 'availableDates',
        },
      ],
    };
    if (this.value) {
      const date = this.value.split('-');
      this.model = {
        isRange: false,
        // singleDate: { jsDate: new Date(this.value) },
        singleDate: {
          date: {
            year: parseInt(date[0]),
            month: parseInt(date[1]),
            day: parseInt(date[2]),
          },
        },
      };
      this.isInvalid = !moment(
        this.value,
        'YYYY-MM-DD',
        true
      ).isValid();

    }

    this.dateChangeEvent.emit(this.value);
  }

  ngOnInit(): void {
    this.isInvalid = false;
    this.myDpOptions = {
      dateRange: false,
      disableDates: this.disableDateList,
      markDates: [
        {
          dates: this.highlightDateList,
          styleClass: 'availableDates',
        },
      ],
      dateFormat: this.config?.format ? this.config.format : 'yyyy-mm-dd',
      disableUntil: this.config?.disableUntil
        ? this.config.disableUntil
        : { year: 0, month: 0, day: 0 },
      disableSince: this.config?.disableSince
        ? this.config.disableSince
        : { year: 0, month: 0, day: 0 },
      firstDayOfWeek: 'su',
      sunHighlight: true,
      markCurrentDay: true,
      openSelectorTopOfInput: false,
      showSelectorArrow: true,
      monthSelector: true,
      yearSelector: true,
      satHighlight: true,
      disableHeaderButtons: true,
      selectorHeight: this.datepickerHeight,
      selectorWidth: this.datepickerWidth,
      closeSelectorOnDateSelect: true,
      closeSelectorOnDocumentClick: true,
      showMonthNumber: false,
      appendSelectorToBody: false,
      focusInputOnDateSelect: true,
      dateRangeDatesDelimiter: ' - ',
      defaultView: DefaultView.Date,
      calendarAnimation: { in: CalAnimation.None, out: CalAnimation.None },
      inputFieldValidation: true,
      stylesData: {
        styles: `
                .myDpDisabled {
                  background: #e8e1e2;
                  font-weight: bold;
                }
                 .availableDates {
                  box-shadow: inset 0 0 0 0.2px #b4b2b2;
                  font-weight: bold ;
                  border-radius: 8px;
                  background:#ffffff !important;
                  pointer-events:all !important;
                }
                .myDpMonthLabel:focus,
                .myDpYearLabel:focus{
                  color: #0f0e0e;
                }
                .myDpMonthLabel,.myDpYearLabel{
                  color: #0f0e0e;
                }
                .myDpMonthLabel:hover,
                .myDpYearLabel:hover{
                  color: #80a5d9 !important;
                }
                .myDpIconLeftArrow,
                .myDpIconRightArrow{
                    color: #de5a4b !important;
                 }
                 .myDpIconLeftArrow:hover,
                 .myDpIconRightArrow:hover{
                     color:#0f0e0e  !important;
                  }
                .myDpHeaderBtn {
                  color: #0f0e0e;;
                  font-weight: bold;
                }
                .myDpHeaderBtn:hover {
                  color: #0f0e0e;
                }
                .myDpSelector:focus{
                  box-shadow: -1px 1px 6px 0px #F9CCCC;
                }
                .myDpWeekDayTitle {
                  background-color: transparent;
                  color: #3d77c5;
                  font-weight: bold;
                }
                .myDpTableSingleDay{
                  font-weight: bold;
                }
                .myDpTableSingleDay:hover{
                  border-radius:50%;
                  background-color:#f2c38d !important;
                }
                .myDpTableSingleYear:hover
                {
                  border-radius:50%;
                  background-color:#f2c38d !important;
                  color:#0a0a0a !important;
                }
                .myDpTableSingleMonth:hover{
                  background-color:#f2c38d !important;
                  color:#0a0a0a !important;
                }
                .myDpTableSingleYear,
                .myDpTableSingleMonth{
                  font-weight: bold;
                color:#3b4ce3
                }
                .myDpMarkCurrYear,
                .myDpMarkCurrMonth{
                  border-bottom: 2px solid;
                }
            `,
      },
    };
    // Initialize to today with javascript date object
    this.prevValue = this.value;
    if (this.value) {
      const date = this.value.split('-');

      this.model = {
        isRange: false,
        singleDate: {
          date: {
            year: parseInt(date[0]),
            month: parseInt(date[1]),
            day: parseInt(date[2]),
          },
        },
      };
    }
  }

  ngAfterContentChecked() {
    if (this.value && this.prevValue != this.value) {
      this.prevValue = this.value;
      if (this.value) {
        const date = this.value.split('-');
        this.model = {
          isRange: false,
          singleDate: {
            date: {
              year: parseInt(date[0]),
              month: parseInt(date[1]),
              day: parseInt(date[2]),
            },
          },
        };
      }
    }
  }

  isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  // dateInput(event) {
  //   this.dateinputdata = event.target.value;

  //   this.crossflag = true;
  // }

  detectValueChange(event) {
    if (this.isEdit) {
      if (event.target.value && event.target.value != this.prevValue) {
        this.isInvalid = !moment(
          event.target.value,
          'YYYY-MM-DD',
          true
        ).isValid();
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!this.isInvalid) {
          this.dateSelected(event);
        }
      }
    }
  }

  clearInput(dp) {
    dp.clearDate();
    this.crossflag = false;
  }
  dateSelected(event) {
    this.crossflag = true;
    const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-([1-9]|0[1-9]|[12]\d|3[01]))/;
    // const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    if (event) {
      if (event.target) {
        let selectedDate = event.target.value;

        const refDate = this.myDpOptions.disableSince;
        this.isInvalid = true;
        const formattedRef = moment(
          refDate.year + '-' + refDate.month + '-' + refDate.day
        ).format('YYYY-MM-DD');

        const yesterday = moment().subtract(1, 'days');
        this.isInvalid = moment(selectedDate).isBefore(yesterday);

        if (moment(selectedDate).format('YYYY-MM-DD') <= formattedRef)
          this.isInvalid =
            !moment(selectedDate, 'YYYY-MM-DD', true).isValid() ||
            !moment(selectedDate, 'YYYY-MM-D', true).isValid();

        this.isInvalid =
          !moment(selectedDate, 'YYYY-MM-DD', true) ||
          !moment(selectedDate, 'YYYY-MM-D', true).isValid();
        // && !regEx.test(selectedDate);

        this.model = event.target.value;
        selectedDate = this.isInvalid
          ? selectedDate
          : moment(selectedDate).format('YYYY-MM-DD');
        this.valueChange.emit(selectedDate);
        this.dateChangeEvent.emit(selectedDate);
      } else {
        this.model = event;
        this.valueChange.emit(event.singleDate.formatted);
        this.dateChangeEvent.emit(event.singleDate.formatted);
        this.isInvalid =
          !moment(event.singleDate.formatted, 'YYYY-MM-DD', true).isValid() ||
          typeof this.model['singleDate']['jsDate'] != 'object';
        // this.isInvalid =
        //   !regEx.test(event.singleDate.formatted) ||
        //   typeof this.model['singleDate']['jsDate'] != 'object';
      }
    } else {
      this.isInvalid = false;
    }
  }

  onCalendarViewChanged(event) {
    if (
      event &&
      (this.selectedMonth != event.month || this.selectedYear != event.year)
    ) {
      this.selectedMonth = event.month;
      this.selectedYear = event.year;
      event.viewChanged = this.viewChanged;
      this.monthSelectionChangeEvent.emit(event);
      this.viewChanged = false;
    }
  }

  refresh() {
    this.viewChanged = false;
    if (this.mydp) {
      this.mydp.headerAction(HeaderAction.NextBtnClick);
      this.mydp.headerAction(HeaderAction.PrevBtnClick);
    }
  }

  onViewActivated(event: ActiveView) {
    if (event === ActiveView.Month || event === ActiveView.Year) {
      this.viewChanged = true;
    }
  }
}
