import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../../../core/core.module';
import { ImageUploadComponent } from './image-upload.component';

@NgModule({
  declarations: [ImageUploadComponent],
  imports: [CommonModule, FormsModule, CoreModule],
  exports: [ImageUploadComponent],
})
export class ImageUploadModule {}
