import { Injectable } from '@angular/core';
import { Action, Selector, State, Store, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import {
  FetchAppointments,
  SetPatientInfo,
  ClearAppointments,
  CancelAppointment,
  UpdateAppointmentStatus,
  FetchAppointmentDetail,
  FetchOscarAppointmentDetailByPatientId,
  InitTwilio,
  SendEmail,
  ClearEmailResponse,
  ClearSMSResponse,
  ClearEncounderResponse,
  SendSMS,
  SendEncounder,
  FetchHealthSummaryDetails,
  FetchTimerAppointments,
  ClearTwilioToken,
  SetVideoCallStatus,
} from '../actions/virtual-care.action';
import { VirtualCareService } from '../services/virtual-care.service';
import { UserAuthState } from './user-auth.state';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {
  ClearHealthSummary,
  SetSelectedPhysician,
  FetchAppointment,
} from '../actions';
import { SweetAlertService } from '@quipo/quipo-lib';
import { of } from 'rxjs';

export class VirtualCareStateModel {
  appointments: any;
  timerAppointments: any;
  selectedPatient: any;
  selectedPhysician: any;
  appointmentDetail: any;
  twilioToken: any;
  emailResponse: any;
  smsResponse: any;
  encounderResponse: any;
  healthSummaryDetails: any;
  isVideoCallOnGoing: boolean;
  statusResponse: any;
}

@State<VirtualCareStateModel>({
  name: 'virtualCare',
  defaults: {
    appointments: undefined,
    timerAppointments: undefined,
    selectedPatient: undefined,
    selectedPhysician: undefined,
    appointmentDetail: undefined,
    twilioToken: undefined,
    emailResponse: undefined,
    smsResponse: undefined,
    encounderResponse: undefined,
    healthSummaryDetails: undefined,
    isVideoCallOnGoing: false,
    statusResponse: undefined,
  },
})
@Injectable()
export class VirtualCareState {
  constructor(
    private store: Store,
    private virtualCareService: VirtualCareService,
    public spinner: NgxSpinnerService,
    public router: Router,
    private alert: SweetAlertService
  ) { }

  @Selector()
  static getAppointments(state: VirtualCareStateModel) {
    return state.appointments;
  }
  @Selector()
  static getTimerAppointments(state: VirtualCareStateModel) {
    return state.timerAppointments;
  }
  @Selector()
  static getSelectedPatient(state: VirtualCareStateModel) {
    return state.selectedPatient;
  }
  @Selector()
  static getSelectedPhysician(state: VirtualCareStateModel) {
    return state.selectedPhysician;
  }

  @Selector()
  static getAppointmentDetail(state: VirtualCareStateModel) {
    return state.appointmentDetail;
  }
  @Selector()
  static getTwilioToken(state: VirtualCareStateModel) {
    return state.twilioToken;
  }
  @Selector()
  static getEmailResponse(state: VirtualCareStateModel) {
    return state.emailResponse;
  }
  @Selector()
  static getSMSResponse(state: VirtualCareStateModel) {
    return state.smsResponse;
  }
  @Selector()
  static getEncounderResponse(state: VirtualCareStateModel) {
    return state.encounderResponse;
  }
  @Selector()
  static getVideoCallStatus(state: VirtualCareStateModel) {
    return state.isVideoCallOnGoing;
  }
  @Selector()
  static getAppointmentStatusResponse(state: VirtualCareStateModel) {
    return state.statusResponse;
  }

  @Action(SetSelectedPhysician)
  setPhysician(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    patchState({
      selectedPhysician: payload ? payload : undefined,
    });
  }

  @Action(FetchAppointments)
  fetchAppointments(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    // this.spinner.show();
    return this.virtualCareService
      .fetchAppointments(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          // this.spinner.hide();
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointments: response.data,
              });
            }
          }
        })
      );
  }

  @Action(FetchTimerAppointments)
  fetchTimerAppointments(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    // this.spinner.show();
    return this.virtualCareService
      .fetchAppointments(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          // this.spinner.hide();
          console.log('fetchapp');
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                timerAppointments: response.data,
              });
            }
          }
        })
      );
  }

  @Action(SetPatientInfo)
  setPatientInfo(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    patchState({
      selectedPatient: payload,
    });
  }
  @Action(ClearAppointments)
  clearAppointments(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    patchState({
      selectedPatient: undefined,
      appointments: undefined,
      appointmentDetail: undefined,
      emailResponse: undefined,
      smsResponse: undefined,
      healthSummaryDetails: undefined,
    });
  }

  // @Action(ClearHealthSummary)
  // clearAppointments(
  //   { patchState }: StateContext<VirtualCareStateModel>,
  //   { payload }: any
  // ) {
  //   patchState({
  //     healthSummaryDetails: undefined,
  //   });
  // }

  @Action(UpdateAppointmentStatus)
  UpdateAppointmentStatus(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selectedDoctor = this.store.selectSnapshot(
      VirtualCareState.getSelectedPhysician
    );
    return this.virtualCareService
      .updateAppointmentStatus(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              const basePath = this.router.url.split('/')[1];
              if (basePath == 'patient')
                this.store.dispatch(new FetchAppointment(payload.patientId));
              else {
                this.store.dispatch(
                  new FetchAppointments({
                    staffId: selectedDoctor
                      ? selectedDoctor.itemCode
                      : loginInfo.staffId,
                    date: payload.selectedDate
                      ? payload.selectedDate
                      : moment().format('YYYY-MM-DD'),
                    filter: false,
                  })
                );
              }
            }
          }
        }),
        catchError((error) => {
          return of()
        })
      );
  }

  @Action(CancelAppointment)
  cancelAppointment(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    const selectedDoctor = this.store.selectSnapshot(
      VirtualCareState.getSelectedPhysician
    );
    return this.virtualCareService
      .cancelAppointment(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              statusResponse: response,
            });
            if (response.statusCode == 200) {
              if (response.data.message) {
                this.alert.toast('', response.data.message);
              } else {
                this.store.dispatch(
                  new FetchAppointments({
                    staffId: selectedDoctor
                      ? selectedDoctor.itemCode
                      : loginInfo.staffId,
                    date: payload.selectedDate
                      ? payload.selectedDate
                      : moment().format('YYYY-MM-DD'),
                    filter: false,
                  })
                );
                const basePath = this.router.url.split('/')[1];
                if (basePath == 'patient')
                  this.router.navigate(['/patient/appointments']);
              }
            }
          }
        })
      );
  }

  @Action(FetchOscarAppointmentDetailByPatientId)
  FetchOscarAppointmentDetailByPatientId(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.virtualCareService
      .FetchOscarAppointmentDetailByPatientId(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointmentDetail: response.data,
              });
            }
          }
        })
      );
  }

  @Action(FetchAppointmentDetail)
  fetchAppointmentDetail(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.virtualCareService
      .fetchAppointmentDetail(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                appointmentDetail: response.data,
              });
            }
          }
        })
      );
  }
  @Action(InitTwilio)
  initTwilio({ patchState }: StateContext<VirtualCareStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.virtualCareService.initTwilio(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              twilioToken: response.data,
            });
          }
        }
      })
    );
  }
  @Action(SendEmail)
  SendEmail(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    // this.spinner.show();
    return this.virtualCareService.SendEmail(payload, loginInfo.token).pipe(
      tap((response) => {
        // this.spinner.hide();
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              emailResponse: { type: payload.type, response: response },
            });
          }
        }
      })
    );
  }
  @Action(SendSMS)
  SendSMS(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.virtualCareService.SendSMS(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            patchState({
              smsResponse: {
                statusCode: 200,
                response: response.data,
              },
            });
          } else {
            patchState({
              smsResponse: {
                statusCode: 500,
                response: undefined,
              },
            });
          }
        }
      })
    );
  }

  @Action(ClearEmailResponse)
  ClearEmailResponse({ patchState }: StateContext<VirtualCareStateModel>) {
    patchState({
      emailResponse: undefined,
    });
  }

  @Action(ClearSMSResponse)
  ClearSMSResponse({ patchState }: StateContext<VirtualCareStateModel>) {
    patchState({
      smsResponse: undefined,
    });
  }

  @Action(ClearTwilioToken)
  ClearTwilioTokenResponse({
    patchState,
  }: StateContext<VirtualCareStateModel>) {
    patchState({
      twilioToken: undefined,
    });
  }

  @Action(ClearEncounderResponse)
  ClearEncounderResponse({ patchState }: StateContext<VirtualCareStateModel>) {
    patchState({
      encounderResponse: undefined,
    });
  }

  @Action(SendEncounder)
  SendEncounder(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.virtualCareService.sendEncounder(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200 && response.data) {
            patchState({
              encounderResponse: {
                statusCode: 200,
                response: response.data,
              },
            });
          } else {
            patchState({
              encounderResponse: {
                statusCode: 500,
                response: undefined,
              },
            });
          }
        }
      })
    );
  }

  @Action(SetVideoCallStatus)
  setVideocallStatus(
    { patchState }: StateContext<VirtualCareStateModel>,
    { payload }: any
  ) {
    patchState({
      isVideoCallOnGoing: payload,
    });
  }
}
