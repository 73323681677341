import { Injectable } from '@angular/core';
import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  AddAvailability,
  ClearAvailabilityResponse,
  ClearAvailabilitySlotResponse,
  DeleteAvailabilitySlot,
  FetchAllAvailability,
  FetchAvailabilitySlotDetails,
  GetActiveAppointmentType,
  UpdateAvailabilitySlot,
  FetchAllWaitingList,
  FetchAvailabilityByAppointmentType,
  ClearAvailabilityByAppointmentType,
  ClearAvailabilityData,
} from '../actions/physician-availability.action';
import { PhysicianAvailabilityService } from '../services/physician-availability.service';
import { UserAuthState } from './user-auth.state';

export interface AvailabilityResponse {
  statusCode: number;
  errorMessage: string;
}

export class AvailabilityStateModel {
  availablityData: any;
  waitingListData: any;
  activeAppointmentTypeList: any;
  availabilityResponse: AvailabilityResponse;
  availablitySlotData: any;
  availableDoctors: any;
}

@State<AvailabilityStateModel>({
  name: 'practitionerAvailability',
  defaults: {
    availablityData: [],
    waitingListData: [],
    activeAppointmentTypeList: [],
    availablitySlotData: undefined,
    availabilityResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
    availableDoctors: undefined,
  },
})
@Injectable()
export class PhysicianAvailabilityState {
  constructor(
    private store: Store,
    private availabilityService: PhysicianAvailabilityService
  ) { }

  @Selector()
  static getAvailabilityDetails(state: AvailabilityStateModel) {
    return state.availablityData;
  }

  @Selector()
  static getWaitingListDetails(state: AvailabilityStateModel) {
    return state.waitingListData;
  }

  @Selector()
  static getAddAvailabilityResponse(state: AvailabilityStateModel) {
    return state.availabilityResponse;
  }

  @Selector()
  static getActiveApppointmentType(state: AvailabilityStateModel) {
    return state.activeAppointmentTypeList;
  }

  @Selector()
  static getAvailabilitySlotDetails(state: AvailabilityStateModel) {
    return state.availablitySlotData;
  }
  @Selector()
  static getAvailabilityByAppointmentType(state: AvailabilityStateModel) {
    return state.availableDoctors;
  }

  @Action(ClearAvailabilityResponse)
  ClearAvailabilityResponse({
    patchState,
  }: StateContext<AvailabilityStateModel>) {
    patchState({
      availabilityResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }

  @Action(ClearAvailabilitySlotResponse)
  ClearAvailabilitySlotResponse({
    patchState,
  }: StateContext<AvailabilityStateModel>) {
    patchState({
      availablitySlotData: undefined,
    });
  }

  @Action(GetActiveAppointmentType)
  FetchActiveAvailability(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.availabilityService
        .getActiveAppointmentType(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    activeAppointmentTypeList: response.data,
                  });
                  return;
                }
              }
              patchState({
                activeAppointmentTypeList: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchAllAvailability)
  FetchAllAvailability(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      // payload['doctorId'] = loginInfo.staffId;
      return this.availabilityService
        .getPhysicianAvailability(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availablityData: response.data,
                  });
                  return;
                }
              }
              patchState({
                availablityData: [],
              });
            }
          })
        );
    }
  }

  @Action(FetchAllWaitingList)
  FetchAllWaitingList(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      // payload['doctorId'] = loginInfo.staffId;
      return this.availabilityService
        .getWaitingList(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    waitingListData: response.data,
                  });
                  return;
                }
              }
              patchState({
                waitingListData: [],
              });
            }
          })
        );
    }
  }

  @Action(AddAvailability)
  UpdateCareTeam(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.availabilityService
        .addPhysicianAvailability(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availabilityResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  availabilityResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                availabilityResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Fetch the Availability's Slot
  @Action(FetchAvailabilitySlotDetails)
  fetchAvailabilitySlotDetail(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.availabilityService
        .getAvailabilitySlotDetails(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availablitySlotData: response.data,
                  });
                  return;
                }
              }
              patchState({
                availablitySlotData: undefined,
              });
            }
          })
        );
    }
  }

  // Update the Availability's Slot
  @Action(UpdateAvailabilitySlot)
  updateAvailabilitySlotDetail(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token && payload) {
      return this.availabilityService
        .updateAvailabilitySlotDetails(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availabilityResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  availabilityResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                availabilityResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Delete the Availability's Slot
  @Action(DeleteAvailabilitySlot)
  deleteAvailabilitySlotDetail(
    { patchState }: StateContext<AvailabilityStateModel>,
    { availabilityId }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.availabilityService
        .deleteAvailabilitySlotDetails(loginInfo.token, availabilityId)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availabilityResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  availabilityResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                availabilityResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  // Fetch the Availability's Slot
  @Action(FetchAvailabilityByAppointmentType)
  fetchAvailabilitybyAppointmentType(
    { patchState }: StateContext<AvailabilityStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.availabilityService
        .getAvailabilitybyAppointmentType(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    availableDoctors: response.data,
                  });
                  return;
                } else {
                  patchState({
                    availableDoctors: [],
                  });
                }
              }
              patchState({
                availableDoctors: undefined,
              });
            }
          })
        );
    }
  }

  @Action(ClearAvailabilityByAppointmentType)
  ClearAvailabilityByAppointmentType({
    patchState,
  }: StateContext<AvailabilityStateModel>) {
    patchState({
      availableDoctors: undefined,
    });
  }

  @Action(ClearAvailabilityData)
  ClearAvailabilityData({
    patchState,
  }: StateContext<AvailabilityStateModel>) {
    patchState({
      availablityData: undefined,
    });
  }
}
