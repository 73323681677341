import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  AddHealthRecord,
  ClearRecord,
  FetchHealthRecord,
  FetchLowestHealthRecord,
  FetchRecentHealthRecord,
} from '../actions';
import { TrackHealthService } from '../services';
import { FamilyMemberState } from './family-member.state';
import { UserAuthState } from './user-auth.state';

export class TrackHealthStateModel {
  healthStatus: any;
  recentStatus: any;
  lowestStatus: any;
  addResponse: any;
}

@State<TrackHealthStateModel>({
  name: 'trackHealth',
  defaults: {
    healthStatus: undefined,
    recentStatus: undefined,
    lowestStatus: undefined,
    addResponse: undefined,
  },
})
@Injectable()
export class TrackHealthState {
  loginInfo: any;
  constructor(
    private store: Store,
    private trackHealthService: TrackHealthService
  ) {}

  @Selector()
  static getHealthStatus(state: TrackHealthStateModel) {
    return state.healthStatus;
  }

  @Selector()
  static getRecentStatus(state: TrackHealthStateModel) {
    return state.recentStatus;
  }
  @Selector()
  static getLowestStatus(state: TrackHealthStateModel) {
    return state.lowestStatus;
  }
  @Selector()
  static getAddResponse(state: TrackHealthStateModel) {
    return state.addResponse;
  }

  @Action(FetchHealthRecord)
  fetchHealthStatus(
    { patchState }: StateContext<TrackHealthStateModel>,
    { payload }: any
  ) {
    const login = this.store.selectSnapshot(UserAuthState.getUserAuthDetails);
    return this.trackHealthService.fetchHealthStatus(login.token, payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              healthStatus: response.data ? response.data : null,
            });
          }
        }
      })
    );
  }

  @Action(FetchRecentHealthRecord)
  fetchRecentHealthRecord({ patchState }: StateContext<TrackHealthStateModel>) {
    const login = this.store.selectSnapshot(UserAuthState.getUserAuthDetails);
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    return this.trackHealthService
      .fetchRecentHealthStatus(login.token, patient.id)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                recentStatus: response.data ? response.data : undefined,
              });
            }
          }
        })
      );
  }

  @Action(FetchLowestHealthRecord)
  fetchLowestHealthRecord({ patchState }: StateContext<TrackHealthStateModel>) {
    const login = this.store.selectSnapshot(UserAuthState.getUserAuthDetails);
    const patient = this.store.selectSnapshot(
      FamilyMemberState.getSelectedPatient
    );
    return this.trackHealthService
      .fetchLowestHealthStatus(login.token, patient.id)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                lowestStatus: response.data ? response.data : undefined,
              });
            }
          }
        })
      );
  }

  @Action(AddHealthRecord)
  addRecord(
    { patchState }: StateContext<TrackHealthStateModel>,
    { payload }: any
  ) {
    const login = this.store.selectSnapshot(UserAuthState.getUserAuthDetails);
    return this.trackHealthService
      .addHealthStatus(login.token, payload.data, payload.id)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            patchState({
              addResponse: response,
            });
          }
        })
      );
  }

  @Action(ClearRecord)
  clearRecord({ patchState }: StateContext<TrackHealthStateModel>) {
    patchState({
      healthStatus: undefined,
      recentStatus: undefined,
      lowestStatus: undefined,
      addResponse: undefined,
    });
  }
}
