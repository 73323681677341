import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private http: HttpClient) { }
  // bookAppointment(payload) {
  //   const url = `${environment.authUrl}/book-appointment`;
  //   return this.http.post(url, payload);
  // }
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  bookAppointment(payload, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/appointment-booking`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchReasons() {
    const url = `${environment.appointmentUrl}/appointment-reason`;
    return this.http.get(url);
  }
  fetchAppointmentQuestionnaire(appointmentId, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/appointment-questionnaire/${appointmentId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchAppointment(patientId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/upcoming-appointments/${patientId}`;
    const now = moment().subtract(15, 'minutes');
    const currentHour = now.format('HH');
    const currentMinute = now.format('mm');
    const obj = {
      date: now.format('YYYY-MM-DD'),
      hour: currentHour,
      minute: currentMinute,
    };
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  fetchAppointmentByOscarId(oscarPatientId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/upcoming-appts-by-demographic-no/${oscarPatientId}`;
    const now = moment().subtract(15, 'minutes');
    const currentHour = now.format('HH');
    const currentMinute = now.format('mm');
    const obj = {
      date: now.format('YYYY-MM-DD'),
      hour: currentHour,
      minute: currentMinute,
    };
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  confirmAppointment(appointmentId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/confirm-appointment/${appointmentId}`;

    return this.http.patch(
      url,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }
  updateAppointment(appointmentId, payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/appointments/${appointmentId}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  // Fetch all ToDo
  fetchToDo(patientId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/to-do/${patientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchGeneralPractitioner(oscarPatientId, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/general-practitioner/${oscarPatientId}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchAvailableAppointments(
    accessToken,
    selectedPatient = undefined
  ): Observable<any> {
    let url = '';
    const userToken = this.getUserTocken();
    if (!userToken) return;
    if (selectedPatient && selectedPatient.oscarDoctorId)
      url = `${environment.serviceIntegrationUrl}/provider-free-slot/${selectedPatient.oscarDoctorId}`;
    else url = `${environment.serviceIntegrationUrl}/appointment-free-slot`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  FetchFirstAvailableAppointment(accessToken, payload): Observable<any> {
    const now = moment();
    const currentHour = now.format('HH');
    const currentMinute = now.format('mm');
    const obj: any = {
      CurrentDate: now.format('YYYY-MM-DD'),
      currentHour: currentHour,
      CurrentMinute: currentMinute,
      reason: payload.reason,
      patientId: payload.patientId,
      oscarPatientId: payload.oscarPatientId,
      localTime: now.format('YYYY-MM-DD HH:mm:ss'),
    };
    const userToken = this.getUserTocken();
    if (!userToken) return;
    if (payload.location && payload.location.itemCode)
      obj.availabilityLocation = payload.location.itemCode.toString();

    const url = `${environment.appointmentUrl}/first-available-slot/${payload.type}`;
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchAvailableAppointmentsByProvider(payload, accessToken): Observable<any> {
    const obj: any = {
      CurrentDate: payload.date,
      currentHour: payload.hour.toString(),
      CurrentMinute: payload.minute.toString(),
      doctorId: payload.oscarDoctorId,
      reason: payload.reason,
      patientId: payload.patientId,
      oscarPatientId: payload.oscarPatientId,
      localTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    const userToken = this.getUserTocken();
    if (!userToken) return;
    if (payload.location)
      obj.availabilityLocation = payload.location.toString();
    const url = `${environment.appointmentUrl}/available-slots/${payload.type}`;
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchAvailableDatesByProvider(payload, accessToken): Observable<any> {
    const obj: any = {
      CurrentDate: payload.date,
      currentHour: payload.hour.toString(),
      CurrentMinute: payload.minute.toString(),
      doctorId: payload.oscarDoctorId,
      reason: payload.reason,
      patientId: payload.patientId,
      oscarPatientId: payload.oscarPatientId,
      localTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    const userToken = this.getUserTocken();
    if (!userToken) return;
    if (payload.location)
      obj.availabilityLocation = payload.location.toString();
    const url = `${environment.appointmentUrl}/available-dates/${payload.type}`;
    return this.http.post(url, obj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  validateAppointment(patientId, payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/check-appointment/${patientId}`;
    return this.http.post(
      url,
      payload,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  fetchCommunityFilters(accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/community-search-filters`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  searchCommunityPhysician(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/community-search`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getReviewLinks(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/review-urls/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  addReview(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/add-review/${payload.id}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getCustomMessage(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.appointmentUrl}/custom-message/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
