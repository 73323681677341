import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PasswordInputComponent } from './password-input.component';
import { CoreModule } from '../../../core/core.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PasswordInputComponent],
  imports: [CommonModule, FormsModule, CoreModule, RouterModule],
  exports: [PasswordInputComponent],
})
export class PasswordInputModule {}
