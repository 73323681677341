<div>
  <label class="switch">
    <input
      type="checkbox"
      class="form-input"
      name="switch"
      quipoMandatory
      [submitted]="submit"
      [(ngModel)]="value"
      (change)="changeHandler()"
    />
    <span class="slider round">
      <span class="text yes">{{ trueText }}</span>
      <span class="text no">{{ falseText }}</span>
    </span>
  </label>
</div>
