export class PostTimer {
  static readonly type = '[Timer] Post';
  constructor(public payload) {}
}
export class FetchActiveTask {
  static readonly type = '[Active Timer] Fetch';
  constructor(public payload) {}
}
export class ClearCurrentTimer {
  static readonly type = '[Timer] Clear';
}
export class ClearActiveTask {
  static readonly type = '[Active Timer] Clear';
}
export class FetchTasksReportByDate {
  static readonly type = '[TaskReport] Fetch';
  constructor(public payload) {}
}
export class UpdateHoursByTimer {
  static readonly type = '[TaskTime] Update';
  constructor(public payload) {}
}
export class FetchTimerGraph {
  static readonly type = '[Timer Plot] Fetch';
  constructor(public payload) {}
}

export class FetchUserAccess {
  static readonly type = '[Timer Access] Fetch';
  constructor(public payload) {}
}

export class ClearUserAccess {
  static readonly type = '[Timer Access] Clear';
}
export class ClearTaskReport {
  static readonly type = '[Task Report] Clear';
}
