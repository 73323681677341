<div class="gender-options">
  <div *ngFor="let data of lookUpData" class="radio-btns">
    <label for="data_{{ data.itemName }}">
      <input
        id="data_{{ data.itemName }}"
        [value]="data.itemCode"
        [ngModel]="radioSelected"
        type="radio"
        name="data"
        (change)="onItemChange(data)"
        class="radio-btn-input"
        data-qa="select-radio"
        aria-label="select"
        (ngModelChange)="change($event)"
      />
      {{ data.itemName | translate }}
    </label>
  </div>
  <!-- <h5>{{ radioSelectedString }}</h5> -->
</div>
