export class ClearResourcesResponse {
  static readonly type = '[Resources] Clear Response';
}

export class ClearResourcesList {
  static readonly type = '[Resourcess] Clear List';
}

export class FetchAllResources {
  static readonly type = '[Resources] Fetch All';
}

export class FetchResourceDetail {
  static readonly type = '[Resources] Fetch Particular Resource';
  constructor(public resourceId) {}
}

export class FetchFavouriteResources {
  static readonly type = '[Resources] Fetch Favourite Resources';
}

export class PatchResourceToFavourite {
  static readonly type = '[Resources] Patch Favourite Resources';
  constructor(public favouriteStatus, public resourceId) {}
}

export class SaveResource {
  static readonly type = '[Resources] Save  Resource';
  constructor(public payload) {}
}

export class PatchResource {
  static readonly type = '[Resources] Update  Resource';
  constructor(public resourceId, public payload) {}
}
