import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete.component';
import { CoreModule } from '../../../core/core.module';
import { FormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
@NgModule({
  declarations: [AutocompleteComponent],
  imports: [CommonModule, CoreModule, FormsModule, AutocompleteLibModule],
  exports: [AutocompleteComponent],
})
export class AutoCompleteModule {}
