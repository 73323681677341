import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor(private http: HttpClient) {}

  getUserToken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  postTimer(payload, accessToken) {
    const userToken = this.getUserToken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/postTimer`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getActiveTask(payload, accessToken) {
    const userToken = this.getUserToken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/fetchActiveTask`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  retrieveTasksByDate(payload, accessToken) {
    const userToken = this.getUserToken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/timerReport`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  updateHoursByLog(payload, accessToken) {
    const userToken = this.getUserToken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/updateHours`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchTimerAccess(payload, accessToken) {
    const userToken = this.getUserToken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/timerAccess`;

    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
