import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@quipo/quipo-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getMatterMostDetails(accessToken): Observable<any> {
    const url = `${environment.authUrl}/matter-most-details`;
    const userToken = this.getUserTocken();
    if (!userToken) return;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
