export class FetchTimerPatients {
  static readonly type = '[Patients] Fetch for Timer';
  constructor(public payload) {}
}
export class FetchPatients {
  static readonly type = '[Patients] Fetch';
  constructor(public payload) { }
}
export class FetchPatientByOscarId {
  static readonly type = '[Patients] Fetch Patient';
  constructor(public payload) { }
}

export class clearPatientDetailUsingOscarID {
  static readonly type = '[Patients] Clear from patientDetail fetched using oscarID';
}

export class ClearPatients {
  static readonly type = '[Patients] Clear';
}

export class ClearTimerPatients {
  static readonly type = '[Patients] Clear for Timer';
}

export class FetchPatientInfo {
  static readonly type = '[Patients] Fetch Detail';
  constructor(public payload) { }
}
export class FetchPatientInfoByOscarId {
  static readonly type = '[OscarPatients] Fetch Details';
  constructor(public payload) { }
}
export class fetchPatientDetailsUsingOscarId {
  static readonly type = '[OscarPatients] Fetch Details of oscar patients';
  constructor(public payload) { }
}
export class ClearPatientInfo {
  static readonly type = '[PatientInfo] Clear';
}
export class ClearPatientInfoByOscarId {
  static readonly type = '[OscarPatientInfo] Clear';
}
export class FetchPrevAppointmentList {
  static readonly type = '[Patients] Fetch appointment list';
  constructor(public payload) { }
}

export class FetchQuestionnaireList {
  static readonly type = '[Patients] Fetch questionnaire list';
  constructor(public payload) { }
}
export class FetchPatientToDo {
  static readonly type = '[Patients] Fetch ToDo';
  constructor(public payload) { }
}
export class AddPatientToDo {
  static readonly type = '[Patients] Add ToDo';
  constructor(public payload) { }
}
export class UpdatePatientToDo {
  static readonly type = '[Patients] Update ToDo';
  constructor(public payload) { }
}
export class DeletePatientToDo {
  static readonly type = '[Patients] Delete ToDo';
  constructor(public payload) { }
}

export class ClearTODOResponse {
  static readonly type = '[Patients] Clear Response';
}
export class ClearOnboardBlockResponse {
  static readonly type = '[Onboard] Block Response';
  constructor() { }
}
export class ClearOnboardRegisterResponse {
  static readonly type = '[Onboard] Register Response';
  constructor() { }
}
export class ClearOnboardAuthorizeResponse {
  static readonly type = '[Onboard] Authorize Response';
  constructor() { }
}
export class ClearPatientsAuthList {
  static readonly type = '[Onboard] Clear List';
  constructor() { }
}

export class FetchPatientMessage {
  static readonly type = '[Patients] Fetch message';
  constructor(public payload) { }
}
export class PatchPatientMessageStatus {
  static readonly type = '[Patients] Patch message';
  constructor(public payload) { }
}

export class AddPatientMessage {
  static readonly type = '[Patients] Add message';
  constructor(public payload) { }
}
export class ClearPatientMessageResponse {
  static readonly type = '[Patients] Clear message Response';
}
export class ClearPatientSelectionFromOscar {
  static readonly type = '[Patients] Clear patient selection Response';
}

export class DeletePatientMessage {
  static readonly type = '[Patients] delete message';
  constructor(public payload) { }
}

export class FetchPatientsAuthList {
  static readonly type = '[Patients] Fetch AuthList';
  constructor(public payload) { }
}

export class RegisterOnBoardPatient {
  static readonly type = '[Patients] Register';
  constructor(public payload) { }
}
export class AuthorizeOnBoardPatient {
  static readonly type = '[Patients] Authorize';
  constructor(public payload) { }
}
export class BlockOnBoardPatient {
  static readonly type = '[Patients] Block';
  constructor(public payload) { }
}

export class SendPatientDeleteRequest {
  static readonly type = '[Patient] Delete Request';
  constructor(public payload) { }
}

export class FetchPatientDeleteRequests {
  static readonly type = '[Patient Delete Requests] Fetch';
}
export class ClearDeleteRequests {
  static readonly type = '[Patient Delete Requests] Clear';
}
export class ClearDeleteResponse {
  static readonly type = '[Patient Delete Response] Clear';
}
