import { Injectable } from '@angular/core';
import { SettingsResponse } from '../interfaces/settings.interface';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserAuthState } from './user-auth.state';
import { SettingService } from '../services/settings.service';
import {
  AddLocationSettings,
  ClearSettingsResponse,
  FetchClinicPoliciesSettings,
  FetchClinicStaffSettings,
  FetchDoctorSettings,
  FetchDoctors,
  FetchGeneralSettings,
  FetchLocationSettings,
  FetchWorkflowPoliciesSettings,
  UpdateClinicPoliciesSettings,
  UpdateClinicStaffSettings,
  UpdateDoctorSettings,
  UpdateGeneralSettings,
  UpdateLocationSettings,
  UpdateWorkflowPoliciesSettings,
  AddClinicPoliciesSettings,
  AddWorkflowPoliciesSettings,
  AddClinicStaffSettings,
  AddDoctorSettings,
  FetchLookupLocations,
  ClearLookupLocationsResponse,
  ClearDoctorsSettings,
  ClearDoctors,
  FetchTimedTask,
  SetTimedTasks,
} from '../actions/settings.action';
import { tap } from 'rxjs/operators';
import { CommonService } from '../services';
import { ClearKeycloakStatus, UpdateKeycloakUser } from '../actions';

export class SettingsStateModel {
  settings: any[];
  locationSettings: any[];
  clientStaffSettings: any[];
  settingsResponse: SettingsResponse;
  doctors: any[];
  doctorSettings: any[];
  clinicPolicies: any[];
  workFlowPolicies: any[];
  lookupLocations: any[];
  timedTasks:any[];
}
@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    settings: [],
    locationSettings: [],
    clientStaffSettings: [],
    settingsResponse: {
      errorMessage: undefined,
      statusCode: 0,
    },
    doctorSettings: [],
    doctors: [],
    clinicPolicies: [],
    workFlowPolicies: [],
    lookupLocations: [],
    timedTasks:[]
  },
})
@Injectable()
export class SettingState {
  constructor(
    private settingService: SettingService,
    private commonService: CommonService,
    private store: Store
  ) {}

  @Selector()
  static getSettingsResponse(state: SettingsStateModel) {
    return state.settingsResponse;
  }

  // Fetch lookup locations
  @Selector()
  static getLookupLocations(state: SettingsStateModel) {
    return state.lookupLocations;
  }

  @Selector()
  static getActiveAppointmentTye(state: SettingsStateModel) {
    return state.lookupLocations;
  }

  @Selector()
  static getTimedTasks(state: SettingsStateModel) {
    return state.timedTasks;
  }
  @Selector()
  static getDoctors(state: SettingsStateModel) {
    return state.doctors;
  }
  @Selector()
  static getGeneralSettingDetails(state: SettingsStateModel) {
    return state.settings;
  }
  // Location Settings
  @Selector()
  static getLocationSettingDetails(state: SettingsStateModel) {
    return state.locationSettings;
  }
 // Doctor Settings
  @Selector()
  static getDoctorSettingDetails(state: SettingsStateModel) {
    return state.doctorSettings;
  }
  //Clinic-Policies Settings
  @Selector()
  static getClinicPoliciesDetails(state: SettingsStateModel) {
    return state.clinicPolicies;
  }
  //Clinic-Staff Settings

  @Selector()
  static getClinicStaffDetails(state: SettingsStateModel) {
    return state.clientStaffSettings;
  }
  //Workflow-Policies Settings
  @Selector()
  static getWorkflowPoliciesDetails(state: SettingsStateModel) {
    return state.workFlowPolicies;
  }
  @Action(ClearSettingsResponse)
  ClearCareteamResponse({ patchState }: StateContext<SettingsStateModel>) {
    patchState({
      settingsResponse: {
        errorMessage: undefined,
        statusCode: 0,
      },
    });
  }

  @Action(FetchLookupLocations)
  FetchAllLookupLocations(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .getLookupLocations(loginInfo.token, payload)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    lookupLocations: response.data,
                  });
                  return;
                }
              }
              patchState({
                lookupLocations: [],
              });
            }
          })
        );
    }
  }

  @Action(ClearLookupLocationsResponse)
  ClearLookupLocationResponse({
    patchState,
  }: StateContext<SettingsStateModel>) {
    patchState({
      lookupLocations: undefined,
    });
  }

  // General Settings


  @Action(FetchGeneralSettings)
  FetchAllGeneralSettings({ patchState }: StateContext<SettingsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService.getGeneralSettings(loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  settings: response.data,
                });
                return;
              }
            }
            patchState({
              settings: [],
            });
          }
        })
      );
    }
  }

  @Action(UpdateGeneralSettings)
  UpdateGeneralSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateGeneralSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .patchGeneralSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }


  @Action(FetchLocationSettings)
  FetchAllLocationSettings({ patchState }: StateContext<SettingsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService.getLocationSettings(loginInfo.token).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  locationSettings: response.data,
                });
                return;
              }
            }
            patchState({
              locationSettings: [],
            });
          }
        })
      );
    }
  }

  @Action(UpdateLocationSettings)
  UpdateLoacationSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateLocationSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .updateLocationSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(AddLocationSettings)
  AddLoacationSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateLocationSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .addLocationSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

 

  @Action(FetchDoctorSettings)
  FetchAllDoctorSettings({ patchState }: StateContext<SettingsStateModel>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    
    return this.settingService.getDoctorSettings(loginInfo.token,payload).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              doctorSettings: response.data,
            });
          }
        }
      })
    );
  }

  @Action(AddDoctorSettings)
  AddDoctorSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateClinicPoliciesSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService.addDoctorSettings(payload, loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                settingsResponse: {
                  statusCode: 200,
                  errorMessage: undefined,
                },
              });
            //  this.store.dispatch(new FetchDoctorSettings({activeStaff:true}));
            } else {
              patchState({
                settingsResponse: {
                  statusCode: response.statusCode,
                  errorMessage: response.message ? response.message : 'Error',
                },
              });
            }
          } else {
            patchState({
              settingsResponse: {
                statusCode: 0,
                errorMessage: undefined,
              },
            });
          }
        }
      })
    );
  }

  @Action(UpdateDoctorSettings)
  UpdateDoctorSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateDoctorSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    if (loginInfo && loginInfo.token) {
      return this.settingService
        .patchDoctorSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (payload.userId) {
                  const userData = {
                    id: payload.userId,
                    staffType: payload.staffType,
                    staffRole: payload.staffRole,
                    obj: {
                      firstName: payload.firstName,
                      lastName: payload.lastName,
                      email: payload.email,
                      username: payload.email,
                    },
                  };
                  this.store.dispatch(new ClearKeycloakStatus());
                  this.store.dispatch(new UpdateKeycloakUser(userData));
                }
                patchState({
                  settingsResponse: {
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(ClearDoctorsSettings)
  ClearDoctorsSettings({ patchState }: StateContext<SettingsStateModel>) {
    patchState({
      doctorSettings: undefined,
    });
  }


  @Action(FetchClinicStaffSettings)
  FetchAllClinicStaffSettings({
    patchState,
  }: StateContext<SettingsStateModel>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService.getClinicStaffSettings(loginInfo.token,payload).pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              if (response.data) {
                patchState({
                  clientStaffSettings: response.data,
                });
                return;
              }
            }
            patchState({
              clientStaffSettings: [],
            });
          }
        })
      );
    }
  }

  @Action(AddClinicStaffSettings)
  AddClinicStaffSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: AddClinicStaffSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .addClinicStaffSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                patchState({
                  settingsResponse: {
                    statusCode: 200,
                    errorMessage: undefined,
                  },
                });
                //this.store.dispatch(new FetchClinicStaffSettings({activeStaff:true}));
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  @Action(UpdateClinicStaffSettings)
  UpdateClinicStaffSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateClinicStaffSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .patchClinicStaffSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                //  console.log('payload',payload);
                if (payload.userId) {
                  const userData = {
                    id: payload.userId,
                    staffType: payload.staffType,
                    staffRole: payload.staffRole,
                    obj: {
                      firstName: payload.firstName,
                      lastName: payload.lastName,
                      email: payload.email,
                      username: payload.email,
                    },
                  };
                  this.store.dispatch(new ClearKeycloakStatus());
                  this.store.dispatch(new UpdateKeycloakUser(userData));
                }
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            } else {
              patchState({
                settingsResponse: {
                  statusCode: 0,
                  errorMessage: undefined,
                },
              });
            }
          })
        );
    }
  }

  
  @Action(FetchClinicPoliciesSettings)
  FetchAllClinicPoliciesSettings({
    patchState,
  }: StateContext<SettingsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService.getClinicPoliciesSettings(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              clinicPolicies: response.data,
            });
          }
        }
      })
    );
  }

  @Action(AddClinicPoliciesSettings)
  AddClinicPoliciesSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateClinicPoliciesSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService
      .addClinicPoliciesSettings(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                settingsResponse: {
                  statusCode: 200,
                  errorMessage: undefined,
                },
              });
              this.store.dispatch(new FetchClinicPoliciesSettings());
            }
          }
        })
      );
  }

  @Action(UpdateClinicPoliciesSettings)
  UpdateClinicPoliciesSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateClinicPoliciesSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .patchClinicPoliciesSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                  this.store.dispatch(new FetchClinicPoliciesSettings());
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            }
          })
        );
    }
  }

  @Action(FetchWorkflowPoliciesSettings)
  FetchAllWorkflowPoliciesSettings({
    patchState,
  }: StateContext<SettingsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService
      .getWorkflowPoliciesSettings(loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                workFlowPolicies: response.data,
              });
            }
          }
        })
      );
  }

  @Action(AddWorkflowPoliciesSettings)
  AddWorkflowPoliciesSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateClinicPoliciesSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService
      .addWorkflowPoliciesSettings(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              patchState({
                settingsResponse: {
                  statusCode: 200,
                  errorMessage: undefined,
                },
              });
              this.store.dispatch(new FetchWorkflowPoliciesSettings());
            }
          }
        })
      );
  }

  @Action(UpdateWorkflowPoliciesSettings)
  UpdateWorkflowPoliciesSettings(
    { patchState }: StateContext<SettingsStateModel>,
    { payload }: UpdateWorkflowPoliciesSettings
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    if (loginInfo && loginInfo.token) {
      return this.settingService
        .patchWorkflowPoliciesSettings(payload, loginInfo.token)
        .pipe(
          tap((response: any) => {
            if (response && response.statusCode) {
              if (response.statusCode == 200) {
                if (response.data) {
                  patchState({
                    settingsResponse: {
                      statusCode: 200,
                      errorMessage: undefined,
                    },
                  });
                  this.store.dispatch(new FetchWorkflowPoliciesSettings());
                }
              } else {
                patchState({
                  settingsResponse: {
                    statusCode: response.statusCode,
                    errorMessage: response.message ? response.message : 'Error',
                  },
                });
              }
            }
          })
        );
    }
  }

  

  @Action(ClearDoctors)
  ClearDoctors({ patchState }: StateContext<SettingsStateModel>) {
    patchState({
      doctors: undefined,
    });
  }

  @Action(FetchDoctors)
  FetchAllDoctors({ patchState }: StateContext<SettingsStateModel>) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService.getDoctors(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              doctors: response.data,
            });
          }
        }
      })
    );
  }

  @Action(FetchTimedTask)
  fetchTimedTasks({patchState}:StateContext<SettingsStateModel>){
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );
    return this.settingService.getTimedTasks(loginInfo.token).pipe(
      tap((response) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            patchState({
              timedTasks: response.data,
            });
          }
        }
      })
    );
  }

  @Action(SetTimedTasks)
  setTimedTasks({patchState}:StateContext<SettingsStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.settingService
      .updateTimedTasks(payload, loginInfo.token)
      .pipe(
        tap((response) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200) {
              this.store.dispatch(new FetchTimedTask());
            }
          }
        })
      );
  }
}
