import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PhoneInputComponent } from './phone-input.component';
import { CoreModule } from '../../../core/core.module';

@NgModule({
  declarations: [PhoneInputComponent],
  imports: [CommonModule, FormsModule, CoreModule],
  exports: [PhoneInputComponent],
})
export class PhoneInputModule {}
