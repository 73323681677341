import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment, mapboxConfig } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) {}
  // doctorsList = [
  //   { itemCode: '1', itemName: 'Alex Mieses' },
  //   { itemCode: '2', itemName: 'Lara Michael' },
  //   { itemCode: '3', itemName: 'John Doe' },
  // ];

  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  fetchData(payload): Observable<any> {
    const url = `${environment.registrationUrl}/lookup/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }
  fetchDoctors(): Observable<any> {
    const url = `${environment.serviceIntegrationUrl}/providers`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }
  initOscar(accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/oscar/init`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  saveOscarVerifierAndToken(accessToken, payload): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/oscar/authorize`;
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchStripeReaders(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/clinic-locations-readers/${payload}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }

  fetchLocation(payload): Observable<any> {
    // payload = payload.replace(' ', '%20');
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${payload}.json?country=ca&bbox=-139.06%2C48.3%2C-114.03%2C60&limit=1000&proximity=-127.64762100000002%2C55.10103542807923&language=en-CA&access_token=${mapboxConfig.accessToken}`;
    // https: const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${payload}.json?country=ca&bbox=-139.06%2C48.3%2C-114.03%2C60&access_token=${mapboxConfig.accessToken}`;
    return this.http.get(url);
  }

  postFile(fileToUpload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/upload-file`;

    return this.http.post(url, fileToUpload, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
  getFile(fileToRetrieve, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.serviceIntegrationUrl}/download-file`;

    return this.http.post(url, fileToRetrieve, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  getDownloadableFile(url): Observable<any> {
    return this.http.post(`${environment.serviceIntegrationUrl}/get-file`, url);
  }

  updateKeyCloakData(payload, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.authUrl}/user`;

    return this.http.put(url, payload, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  fetchTutorialsList(accessToken, payload): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/tutorials`;
    return this.http.post(
      url,
      { searchConditions: payload },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          UserAuth: userToken,
        }),
      }
    );
  }

  getTutorialVideo(fileToRetrieve, accessToken): Observable<any> {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.adminUrl}/download-tutorial`;

    return this.http.post(url, fileToRetrieve, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    });
  }

  downloadFile(url): Observable<any> {
    return this.http.get(`${environment.serviceIntegrationUrl}/${url}`);
  }
}
