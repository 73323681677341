import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhysicianService {
  constructor(private http: HttpClient) {}
  getUserTocken() {
    let userToken = sessionStorage.getItem('loggedUserToken');
    if (!userToken) userToken = localStorage.getItem('loggedUserToken');
    return userToken;
  }

  getPhysicianProfileDetails(id, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/my-profile/${id}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }

  patchPhysicianProfileDetails(payload, id, accessToken) {
    const userToken = this.getUserTocken();
    if (!userToken) return;
    const url = `${environment.registrationUrl}/my-profile/${id}`;
    return this.http.patch(url, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        UserAuth: userToken,
      }),
    });
  }
}
