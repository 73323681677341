import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ClearCurrentTimer,
  ClearUserAccess,
  FetchTasksReportByDate,
  FetchUserAccess,
  PostTimer,
  UpdateHoursByTimer,
} from '../actions';
import { TimerService } from '../services/timer.service';
import { UserAuthState } from '@quipo/quipo-lib';
import { tap } from 'rxjs/operators';
import {
  ClearActiveTask,
  ClearTaskReport,
  FetchActiveTask,
} from '../actions/timer.action';

export class TimerStateModel {
  timers: any;
  activeTasks: any;
  currentTimer: any;
  tasksReport: any;
  timerPlot: any;
  allowTimerAccess: boolean;
  updateHoursStatus: any
}

@State<TimerStateModel>({
  name: 'timerTasks',
  defaults: {
    timers: undefined,
    activeTasks: undefined,
    currentTimer: undefined,
    tasksReport: undefined,
    timerPlot: undefined,
    allowTimerAccess: undefined,
    updateHoursStatus: undefined,
  },
})
@Injectable()
export class TimerState {
  constructor(public store: Store, public timerService: TimerService) {}

  @Selector()
  static getCurrentTimer(state: TimerStateModel) {
    return state.currentTimer;
  }
  @Selector()
  static getActiveTasks(state: TimerStateModel) {
    return state.activeTasks;
  }
  @Selector()
  static getTasksReport(state: TimerStateModel) {
    return state.tasksReport;
  }
  @Selector()
  static getTimerPlot(state: TimerStateModel) {
    return state.timerPlot;
  }
  @Selector()
  static getTimerAccess(state: TimerStateModel) {
    return state.allowTimerAccess;
  }

  @Selector()
  static getUpdateHoursByTimerStatus(state: TimerStateModel) {
    return state.updateHoursStatus;
  }

  @Action(PostTimer)
  postTimer({ patchState }: StateContext<TimerStateModel>, { payload }: any) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.timerService.postTimer(payload, loginInfo.token).pipe(
      tap((response: any) => {
        if (response && response.data)
          patchState({
            currentTimer: response.data[1]
              ? response.data[1][0]
              : response.data,
          });
      })
    );
  }

  @Action(FetchActiveTask)
  fetchActiveTask(
    { patchState }: StateContext<TimerStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.timerService.getActiveTask(payload, loginInfo.token).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                activeTasks: response.data,
              });
            }
          }
        }
      })
    );
  }

  @Action(ClearCurrentTimer)
  clearTimer({ patchState }: StateContext<TimerStateModel>) {
    patchState({
      currentTimer: undefined,
    });
  }
  @Action(ClearActiveTask)
  clearActiveTask({ patchState }: StateContext<TimerStateModel>) {
    patchState({
      activeTasks: undefined,
    });
  }
  @Action(ClearTaskReport)
  clearTaskReport({ patchState }: StateContext<TimerStateModel>) {
    patchState({
      tasksReport: undefined,
      timerPlot: undefined,
    });
  }

  @Action(FetchTasksReportByDate)
  fetchTasksByDate(
    { patchState }: StateContext<TimerStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.timerService.retrieveTasksByDate(payload, loginInfo.token).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data) {
              patchState({
                tasksReport: response.data.reports,
                timerPlot: response.data.plot,
              });
            }
          }
        }
      })
    );
  }

  @Action(UpdateHoursByTimer)
  updateTaskHours(
    { patchState }: StateContext<TimerStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.timerService
      .updateHoursByLog(payload.timer, loginInfo.token)
      .pipe(
        tap((response: any) => {
          if (response && response.statusCode) {
            if (response.statusCode == 200 && response.data) {
               patchState({
                updateHoursStatus: true
               })
                // this.store.dispatch(
                //   new FetchTasksReportByDate(payload.filterData)
                // );
            }else{
              patchState({
                updateHoursStatus: false
               })
            }
          }
        })
      );
  }

  @Action(ClearUserAccess)
  clearUserAccess({ patchState }: StateContext<TimerStateModel>) {
    patchState({
      allowTimerAccess: undefined,
    });
  }

  @Action(FetchUserAccess)
  fetchUserAccess(
    { patchState }: StateContext<TimerStateModel>,
    { payload }: any
  ) {
    const loginInfo = this.store.selectSnapshot(
      UserAuthState.getUserAuthDetails
    );

    return this.timerService.fetchTimerAccess(payload, loginInfo.token).pipe(
      tap((response: any) => {
        if (response && response.statusCode) {
          if (response.statusCode == 200) {
            if (response.data && response.data.hasOwnProperty('allowUser')) {
              patchState({
                allowTimerAccess: response.data.allowUser,
              });
            } else {
              patchState({
                allowTimerAccess: false,
              });
            }
          } else {
            patchState({
              allowTimerAccess: false,
            });
          }
        }
      })
    );
  }
}
