import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchData } from '../../../core/actions/common.actions';
import { BaseComponent } from '../../../core//base';
import {
  ListData,
  LookUpData,
} from '../../../core/interfaces/common.interface';
import { CommonState } from '../../../core/state/common.state';

@Component({
  selector: 'quipo-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  selectedValue: string;
  @Input() lookupItem: string;
  radioSelected: any;

  @Select(CommonState.getLookUp) lookUpData$: Observable<LookUpData[]>;
  lookUpData: ListData[];

  constructor(store: Store) {
    super(store);
    this.lookUpData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data && this.lookupItem) {
        const index = data.findIndex((x) => x.lookupCode === this.lookupItem);
        this.lookUpData = index >= 0 ? data[index]?.lookupValues : [];
        if (this.value && this.value['itemCode'] && this.lookUpData) {
          this.radioSelected = this.lookUpData.find(
            (Item) => Item.itemCode === this.value['itemCode']
          );
        }
        this.loadLookupData();
      }
    });
  }

  change(newValue) {
    if (this.lookUpData) {
      const selectedItem = this.lookUpData.find(
        (Item) => Item.itemCode === newValue
      );
      if (selectedItem) this.valueChange.emit(selectedItem);
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchData(this.lookupItem));
  }

  loadLookupData() {
    if (
      this.value &&
      this.lookUpData &&
      this.lookUpData.length > 0 &&
      this.value &&
      this.value['itemCode']
    ) {
      const data = this.lookUpData.find(
        (Item) => Item.itemCode === this.value['itemCode']
      );
      this.onItemChange(data);
    } else if (this.lookUpData && this.lookUpData[0]) {
      this.onItemChange(this.lookUpData[0]);
      this.change(this.lookUpData[0]?.itemCode);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.change(this.radioSelected);
    }, 0);
  }

  onItemChange(item) {
    if (item) this.radioSelected = item.itemCode;
  }
}
